export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: Date; output: Date };
  ISO8601Date: { input: Date; output: Date };
  ISO8601DateTime: { input: Date; output: Date };
  JSON: { input: unknown; output: unknown };
  Map: { input: Record<string, string>; output: Record<string, string> };
  Upload: { input: any; output: any };
};

/** A user account on Kitsu */
export type Account = WithTimestamps & {
  readonly __typename?: 'Account';
  /** The country this user resides in */
  readonly country?: Maybe<Scalars['String']['output']>;
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  /** The email addresses associated with this account */
  readonly email: ReadonlyArray<Scalars['String']['output']>;
  /** The features this user has access to */
  readonly enabledFeatures: ReadonlyArray<Scalars['String']['output']>;
  /** Facebook account linked to the account */
  readonly facebookId?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  /** Primary language for the account */
  readonly language?: Maybe<Scalars['String']['output']>;
  /** Longest period an account has had a PRO subscription for in seconds */
  readonly maxProStreak?: Maybe<Scalars['Int']['output']>;
  /** The PRO subscription for this account */
  readonly proSubscription?: Maybe<ProSubscription>;
  /** The profile for this account */
  readonly profile: Profile;
  /** Media rating system used for the account */
  readonly ratingSystem: RatingSystemEnum;
  /** Whether Not Safe For Work content is accessible */
  readonly sfwFilter?: Maybe<Scalars['Boolean']['output']>;
  /** The level of the SFW Filter */
  readonly sfwFilterPreference?: Maybe<SfwFilterPreferenceEnum>;
  /** The site-wide permissions this user has access to */
  readonly sitePermissions: ReadonlyArray<SitePermissionEnum>;
  /** Time zone of the account */
  readonly timeZone?: Maybe<Scalars['String']['output']>;
  /** Preferred language for media titles */
  readonly titleLanguagePreference?: Maybe<TitleLanguagePreferenceEnum>;
  /** Twitter account linked to the account */
  readonly twitterId?: Maybe<Scalars['String']['output']>;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type AccountChangePasswordErrorsUnion =
  | NotAuthenticatedError
  | NotAuthorizedError
  | ValidationError;

export type AccountChangePasswordInput = {
  /** The new password to set */
  readonly newPassword: Scalars['String']['input'];
  /** The current, existing password for the account */
  readonly oldPassword: Scalars['String']['input'];
};

/** Autogenerated return type of AccountChangePassword */
export type AccountChangePasswordPayload = {
  readonly __typename?: 'AccountChangePasswordPayload';
  readonly errors?: Maybe<ReadonlyArray<AccountChangePasswordErrorsUnion>>;
  readonly result?: Maybe<Account>;
};

export type AccountCreateErrorsUnion = ValidationError;

export type AccountCreateInput = {
  /** The email address to reset the password for */
  readonly email: Scalars['String']['input'];
  /** An external identity to associate with the account on creation */
  readonly externalIdentity?: InputMaybe<AccountExternalIdentityInput>;
  /** The name of the user */
  readonly name: Scalars['String']['input'];
  /** The password for the user */
  readonly password: Scalars['String']['input'];
};

/** Autogenerated return type of AccountCreate */
export type AccountCreatePayload = {
  readonly __typename?: 'AccountCreatePayload';
  readonly errors?: Maybe<ReadonlyArray<AccountCreateErrorsUnion>>;
  readonly result?: Maybe<Account>;
};

export type AccountExternalIdentityInput = {
  readonly id: Scalars['String']['input'];
  readonly provider: ExternalIdentityProviderEnum;
};

export type AccountMutations = {
  readonly __typename?: 'AccountMutations';
  /** Change your Kitsu account password */
  readonly changePassword?: Maybe<AccountChangePasswordPayload>;
  /** Send a password reset email */
  readonly sendPasswordReset?: Maybe<AccountSendPasswordResetPayload>;
  /** Update the account of the current user. */
  readonly update?: Maybe<AccountUpdatePayload>;
};

export type AccountMutationsChangePasswordArgs = {
  input: AccountChangePasswordInput;
};

export type AccountMutationsSendPasswordResetArgs = {
  email: Scalars['String']['input'];
};

export type AccountMutationsUpdateArgs = {
  input: AccountUpdateInput;
};

/** Autogenerated return type of AccountSendPasswordReset */
export type AccountSendPasswordResetPayload = {
  readonly __typename?: 'AccountSendPasswordResetPayload';
  readonly email: Scalars['String']['output'];
};

export type AccountUpdateErrorsUnion =
  | NotAuthenticatedError
  | NotAuthorizedError
  | NotFoundError;

export type AccountUpdateInput = {
  /** The country of the user */
  readonly country?: InputMaybe<Scalars['String']['input']>;
  /** How media titles will get visualized */
  readonly preferredTitleLanguage?: InputMaybe<TitleLanguagePreferenceEnum>;
  /** The preferred rating system */
  readonly ratingSystem?: InputMaybe<RatingSystemEnum>;
  /** The SFW Filter setting */
  readonly sfwFilterPreference?: InputMaybe<SfwFilterPreferenceEnum>;
  /** The theme displayed on Kitsu */
  readonly siteTheme?: InputMaybe<SiteThemeEnum>;
  /** The time zone of the user */
  readonly timeZone?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AccountUpdate */
export type AccountUpdatePayload = {
  readonly __typename?: 'AccountUpdatePayload';
  readonly errors?: Maybe<ReadonlyArray<AccountUpdateErrorsUnion>>;
  readonly result?: Maybe<Account>;
};

export enum AgeRatingEnum {
  /** Acceptable for all ages */
  G = 'G',
  /** Parental guidance suggested; should be safe for preteens and older */
  Pg = 'PG',
  /** Possible lewd or intense themes; should be safe for teens and older */
  R = 'R',
  /** Contains adult content or themes; should only be viewed by adults */
  R18 = 'R18',
}

/** Generic Amount Consumed based on Media */
export type AmountConsumed = {
  /** Total media completed atleast once. */
  readonly completed: Scalars['Int']['output'];
  readonly id: Scalars['ID']['output'];
  /** Total amount of media. */
  readonly media: Scalars['Int']['output'];
  /** The profile related to the user for this stat. */
  readonly profile: Profile;
  /** Last time we fully recalculated this stat. */
  readonly recalculatedAt: Scalars['ISO8601Date']['output'];
  /** Total progress of library including reconsuming. */
  readonly units: Scalars['Int']['output'];
};

export type Anime = Episodic &
  Media &
  WithTimestamps & {
    readonly __typename?: 'Anime';
    /** The recommended minimum age group for this media */
    readonly ageRating?: Maybe<AgeRatingEnum>;
    /** An explanation of why this received the age rating it did */
    readonly ageRatingGuide?: Maybe<Scalars['String']['output']>;
    /** The average rating of this media amongst all Kitsu users */
    readonly averageRating?: Maybe<Scalars['Float']['output']>;
    /** The rank of this media by rating */
    readonly averageRatingRank?: Maybe<Scalars['Int']['output']>;
    /** A large banner image for this media */
    readonly bannerImage?: Maybe<Image>;
    /** A list of categories for this media */
    readonly categories: CategoryConnection;
    /** The characters who starred in this media */
    readonly characters: MediaCharacterConnection;
    readonly createdAt: Scalars['ISO8601DateTime']['output'];
    /** A brief (mostly spoiler free) summary or description of the media. */
    readonly description: Scalars['Map']['output'];
    /** the day that this media made its final release */
    readonly endDate?: Maybe<Scalars['Date']['output']>;
    /** The number of episodes in this series */
    readonly episodeCount?: Maybe<Scalars['Int']['output']>;
    /** The general length (in seconds) of each episode */
    readonly episodeLength?: Maybe<Scalars['Int']['output']>;
    /** Episodes for this media */
    readonly episodes: EpisodeConnection;
    /** The number of users with this in their favorites */
    readonly favoritesCount?: Maybe<Scalars['Int']['output']>;
    readonly id: Scalars['ID']['output'];
    /** A list of mappings for this media */
    readonly mappings: MappingConnection;
    /** Your library entry related to this media. */
    readonly myLibraryEntry?: Maybe<LibraryEntry>;
    /** A list of your wiki submissions for this media */
    readonly myWikiSubmissions: WikiSubmissionConnection;
    /** The time of the next release of this media */
    readonly nextRelease?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /** The countries in which the media was originally primarily produced */
    readonly originCountries: ReadonlyArray<Scalars['String']['output']>;
    /** The languages the media was originally produced in */
    readonly originLanguages: ReadonlyArray<Scalars['String']['output']>;
    /**
     * The country in which the media was primarily produced
     * @deprecated Replaced with originCountries and originLanguages
     */
    readonly originalLocale?: Maybe<Scalars['String']['output']>;
    /** The poster image of this media */
    readonly posterImage?: Maybe<Image>;
    /** All posts that tag this media. */
    readonly posts: PostConnection;
    /** The companies which helped to produce this media */
    readonly productions: MediaProductionConnection;
    /** A list of quotes from this media */
    readonly quotes: QuoteConnection;
    /** A list of reactions for this media */
    readonly reactions: MediaReactionConnection;
    /** The season this was released in */
    readonly season?: Maybe<ReleaseSeasonEnum>;
    /** Whether the media is Safe-for-Work */
    readonly sfw: Scalars['Boolean']['output'];
    /** The URL-friendly identifier of this media */
    readonly slug: Scalars['String']['output'];
    /** The staff members who worked on this media */
    readonly staff: MediaStaffConnection;
    /** The day that this media first released */
    readonly startDate?: Maybe<Scalars['Date']['output']>;
    /** The current releasing status of this media */
    readonly status: ReleaseStatusEnum;
    /** The stream links. */
    readonly streamingLinks: StreamingLinkConnection;
    /** A secondary type for categorizing Anime. */
    readonly subtype: AnimeSubtypeEnum;
    /** Description of when this media is expected to release */
    readonly tba?: Maybe<Scalars['String']['output']>;
    /** The titles for this media in various locales */
    readonly titles: TitlesList;
    /** The total length (in seconds) of the entire series */
    readonly totalLength?: Maybe<Scalars['Int']['output']>;
    /** Anime or Manga. */
    readonly type: Scalars['String']['output'];
    readonly updatedAt: Scalars['ISO8601DateTime']['output'];
    /** The number of users with this in their library */
    readonly userCount?: Maybe<Scalars['Int']['output']>;
    /** The rank of this media by popularity */
    readonly userCountRank?: Maybe<Scalars['Int']['output']>;
    /** Video id for a trailer on YouTube */
    readonly youtubeTrailerVideoId?: Maybe<Scalars['String']['output']>;
  };

export type AnimeCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<MediaCategorySortOption>>>;
};

export type AnimeCharactersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<MediaCharacterSortOption>>>;
};

export type AnimeDescriptionArgs = {
  locales?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type AnimeEpisodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<EpisodeSortOption>>>;
};

export type AnimeMappingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AnimeMyWikiSubmissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<WikiSubmissionSortOption>>>;
};

export type AnimePostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<PostSortOption>>>;
};

export type AnimeProductionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AnimeQuotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AnimeReactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<MediaReactionSortOption>>>;
};

export type AnimeStaffArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AnimeStreamingLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AnimeAmountConsumed = AmountConsumed & {
  readonly __typename?: 'AnimeAmountConsumed';
  /** Total media completed atleast once. */
  readonly completed: Scalars['Int']['output'];
  readonly id: Scalars['ID']['output'];
  /** Total amount of media. */
  readonly media: Scalars['Int']['output'];
  /** The profile related to the user for this stat. */
  readonly profile: Profile;
  /** Last time we fully recalculated this stat. */
  readonly recalculatedAt: Scalars['ISO8601Date']['output'];
  /** Total time spent in minutes. */
  readonly time: Scalars['Int']['output'];
  /** Total progress of library including reconsuming. */
  readonly units: Scalars['Int']['output'];
};

export type AnimeCategoryBreakdown = CategoryBreakdown & {
  readonly __typename?: 'AnimeCategoryBreakdown';
  /** A Map of category_id -> count for all categories present on the library entries */
  readonly categories: Scalars['Map']['output'];
  readonly id: Scalars['ID']['output'];
  /** The profile related to the user for this stat. */
  readonly profile: Profile;
  /** Last time we fully recalculated this stat. */
  readonly recalculatedAt: Scalars['ISO8601Date']['output'];
  /** The total amount of library entries. */
  readonly total: Scalars['Int']['output'];
};

/** The connection type for Anime. */
export type AnimeConnection = {
  readonly __typename?: 'AnimeConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<AnimeEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Anime>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

export type AnimeCreateInput = {
  readonly ageRating?: InputMaybe<AgeRatingEnum>;
  readonly ageRatingGuide?: InputMaybe<Scalars['String']['input']>;
  readonly bannerImage?: InputMaybe<Scalars['Upload']['input']>;
  readonly description: Scalars['Map']['input'];
  readonly endDate?: InputMaybe<Scalars['Date']['input']>;
  readonly episodeCount?: InputMaybe<Scalars['Int']['input']>;
  readonly episodeLength?: InputMaybe<Scalars['Int']['input']>;
  readonly posterImage?: InputMaybe<Scalars['Upload']['input']>;
  readonly startDate?: InputMaybe<Scalars['Date']['input']>;
  readonly tba?: InputMaybe<Scalars['String']['input']>;
  readonly titles: TitlesListInput;
  readonly youtubeTrailerVideoId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AnimeCreate */
export type AnimeCreatePayload = {
  readonly __typename?: 'AnimeCreatePayload';
  readonly anime?: Maybe<Anime>;
  readonly errors?: Maybe<ReadonlyArray<Error>>;
};

/** Autogenerated return type of AnimeDelete */
export type AnimeDeletePayload = {
  readonly __typename?: 'AnimeDeletePayload';
  readonly anime?: Maybe<GenericDelete>;
  readonly errors?: Maybe<ReadonlyArray<Error>>;
};

/** An edge in a connection. */
export type AnimeEdge = {
  readonly __typename?: 'AnimeEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Anime>;
};

export type AnimeMutations = {
  readonly __typename?: 'AnimeMutations';
  /** Create an Anime. */
  readonly create?: Maybe<AnimeCreatePayload>;
  /** Delete an Anime. */
  readonly delete?: Maybe<AnimeDeletePayload>;
  /** Update an Anime. */
  readonly update?: Maybe<AnimeUpdatePayload>;
};

export type AnimeMutationsCreateArgs = {
  input: AnimeCreateInput;
};

export type AnimeMutationsDeleteArgs = {
  input: GenericDeleteInput;
};

export type AnimeMutationsUpdateArgs = {
  input: AnimeUpdateInput;
};

export enum AnimeSubtypeEnum {
  Movie = 'MOVIE',
  Music = 'MUSIC',
  /** Original Net Animation (Web Anime). */
  Ona = 'ONA',
  /** Original Video Animation. Anime directly released to video market. */
  Ova = 'OVA',
  /** Spinoffs or Extras of the original. */
  Special = 'SPECIAL',
  Tv = 'TV',
}

export type AnimeUpdateInput = {
  readonly ageRating?: InputMaybe<AgeRatingEnum>;
  readonly ageRatingGuide?: InputMaybe<Scalars['String']['input']>;
  readonly bannerImage?: InputMaybe<Scalars['Upload']['input']>;
  readonly description?: InputMaybe<Scalars['Map']['input']>;
  readonly endDate?: InputMaybe<Scalars['Date']['input']>;
  readonly episodeCount?: InputMaybe<Scalars['Int']['input']>;
  readonly episodeLength?: InputMaybe<Scalars['Int']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly posterImage?: InputMaybe<Scalars['Upload']['input']>;
  readonly startDate?: InputMaybe<Scalars['Date']['input']>;
  readonly tba?: InputMaybe<Scalars['String']['input']>;
  readonly titles?: InputMaybe<TitlesListInput>;
  readonly youtubeTrailerVideoId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AnimeUpdate */
export type AnimeUpdatePayload = {
  readonly __typename?: 'AnimeUpdatePayload';
  readonly anime?: Maybe<Anime>;
  readonly errors?: Maybe<ReadonlyArray<Error>>;
};

/** A blocked user entry of an Account. */
export type Block = WithTimestamps & {
  readonly __typename?: 'Block';
  /** User who got blocked. */
  readonly blockedUser: Profile;
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
  /** User who blocked. */
  readonly user: Profile;
};

/** The connection type for Block. */
export type BlockConnection = {
  readonly __typename?: 'BlockConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<BlockEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Block>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

export type BlockCreateErrorsUnion =
  | NotAuthenticatedError
  | NotAuthorizedError
  | NotFoundError;

export type BlockCreateInput = {
  /** The id of the user to block. */
  readonly blockedId: Scalars['ID']['input'];
};

/** Autogenerated return type of BlockCreate */
export type BlockCreatePayload = {
  readonly __typename?: 'BlockCreatePayload';
  readonly errors?: Maybe<ReadonlyArray<BlockCreateErrorsUnion>>;
  readonly result?: Maybe<Block>;
};

export type BlockDeleteErrorsUnion =
  | NotAuthenticatedError
  | NotAuthorizedError
  | NotFoundError;

export type BlockDeleteInput = {
  /** The id of the block. */
  readonly blockId: Scalars['ID']['input'];
};

/** Autogenerated return type of BlockDelete */
export type BlockDeletePayload = {
  readonly __typename?: 'BlockDeletePayload';
  readonly errors?: Maybe<ReadonlyArray<BlockDeleteErrorsUnion>>;
  readonly result?: Maybe<Block>;
};

/** An edge in a connection. */
export type BlockEdge = {
  readonly __typename?: 'BlockEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Block>;
};

export type BlockMutations = {
  readonly __typename?: 'BlockMutations';
  /** Create a Block entry. */
  readonly create?: Maybe<BlockCreatePayload>;
  /** Delete a Block entry. */
  readonly delete?: Maybe<BlockDeletePayload>;
};

export type BlockMutationsCreateArgs = {
  input: BlockCreateInput;
};

export type BlockMutationsDeleteArgs = {
  input: BlockDeleteInput;
};

/** Information about a specific Category */
export type Category = WithTimestamps & {
  readonly __typename?: 'Category';
  /** The child categories. */
  readonly children?: Maybe<CategoryConnection>;
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  /** A brief summary or description of the catgory. */
  readonly description: Scalars['Map']['output'];
  readonly id: Scalars['ID']['output'];
  /** Whether the category is Not-Safe-for-Work. */
  readonly isNsfw: Scalars['Boolean']['output'];
  /** The parent category. Each category can have one parent. */
  readonly parent?: Maybe<Category>;
  /** The top-level ancestor category */
  readonly root?: Maybe<Category>;
  /** The URL-friendly identifier of this Category. */
  readonly slug: Scalars['String']['output'];
  /** The name of the category. */
  readonly title: Scalars['Map']['output'];
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Information about a specific Category */
export type CategoryChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Information about a specific Category */
export type CategoryDescriptionArgs = {
  locales?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** Information about a specific Category */
export type CategoryTitleArgs = {
  locales?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** Generic Category Breakdown based on Media */
export type CategoryBreakdown = {
  /** A Map of category_id -> count for all categories present on the library entries */
  readonly categories: Scalars['Map']['output'];
  readonly id: Scalars['ID']['output'];
  /** The profile related to the user for this stat. */
  readonly profile: Profile;
  /** Last time we fully recalculated this stat. */
  readonly recalculatedAt: Scalars['ISO8601Date']['output'];
  /** The total amount of library entries. */
  readonly total: Scalars['Int']['output'];
};

/** The connection type for Category. */
export type CategoryConnection = {
  readonly __typename?: 'CategoryConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<CategoryEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Category>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CategoryEdge = {
  readonly __typename?: 'CategoryEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Category>;
};

/** A single chapter of a manga */
export type Chapter = Unit &
  WithTimestamps & {
    readonly __typename?: 'Chapter';
    readonly createdAt: Scalars['ISO8601DateTime']['output'];
    /** A brief summary or description of the unit */
    readonly description: Scalars['Map']['output'];
    readonly id: Scalars['ID']['output'];
    /** Number of pages in chapter. */
    readonly length?: Maybe<Scalars['Int']['output']>;
    /** The manga this chapter is in. */
    readonly manga: Manga;
    /** The sequence number of this unit */
    readonly number: Scalars['Int']['output'];
    /** When this chapter was released */
    readonly releasedAt?: Maybe<Scalars['ISO8601Date']['output']>;
    /** A thumbnail image for the unit */
    readonly thumbnail?: Maybe<Image>;
    /** The titles for this unit in various locales */
    readonly titles: TitlesList;
    readonly updatedAt: Scalars['ISO8601DateTime']['output'];
    /** The volume this chapter is in. */
    readonly volume?: Maybe<Volume>;
  };

/** A single chapter of a manga */
export type ChapterDescriptionArgs = {
  locales?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** The connection type for Chapter. */
export type ChapterConnection = {
  readonly __typename?: 'ChapterConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<ChapterEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Chapter>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ChapterEdge = {
  readonly __typename?: 'ChapterEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Chapter>;
};

export enum ChapterSortEnum {
  CreatedAt = 'CREATED_AT',
  Number = 'NUMBER',
  UpdatedAt = 'UPDATED_AT',
}

export type ChapterSortOption = {
  readonly direction: SortDirection;
  readonly on: ChapterSortEnum;
};

/** Information about a Character in the Kitsu database */
export type Character = WithTimestamps & {
  readonly __typename?: 'Character';
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  /** A brief summary or description of the character. */
  readonly description: Scalars['Map']['output'];
  readonly id: Scalars['ID']['output'];
  /** An image of the character */
  readonly image?: Maybe<Image>;
  /** Media this character appears in. */
  readonly media?: Maybe<MediaCharacterConnection>;
  /** The name for this character in various locales */
  readonly names?: Maybe<TitlesList>;
  /** The original media this character showed up in */
  readonly primaryMedia?: Maybe<Media>;
  /** The URL-friendly identifier of this character */
  readonly slug: Scalars['String']['output'];
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Information about a Character in the Kitsu database */
export type CharacterDescriptionArgs = {
  locales?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** Information about a Character in the Kitsu database */
export type CharacterMediaArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum CharacterRoleEnum {
  /** A background character who generally only appears in a few episodes */
  Background = 'BACKGROUND',
  /** A character from a different franchise making a (usually brief) appearance */
  Cameo = 'CAMEO',
  /** A character who appears throughout a series and is a focal point of the media */
  Main = 'MAIN',
  /** A character who appears in multiple episodes but is not a main character */
  Recurring = 'RECURRING',
}

/** Information about a VA (Person) voicing a Character in a Media */
export type CharacterVoice = WithTimestamps & {
  readonly __typename?: 'CharacterVoice';
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  /** The company who hired this voice actor to play this role */
  readonly licensor?: Maybe<Producer>;
  /** The BCP47 locale tag for the voice acting role */
  readonly locale: Scalars['String']['output'];
  /** The MediaCharacter node */
  readonly mediaCharacter: MediaCharacter;
  /** The person who voice acted this role */
  readonly person: Person;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for CharacterVoice. */
export type CharacterVoiceConnection = {
  readonly __typename?: 'CharacterVoiceConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<CharacterVoiceEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<CharacterVoice>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CharacterVoiceEdge = {
  readonly __typename?: 'CharacterVoiceEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<CharacterVoice>;
};

export enum CharacterVoiceSortEnum {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type CharacterVoiceSortOption = {
  readonly direction: SortDirection;
  readonly on: CharacterVoiceSortEnum;
};

/** A comment on a post */
export type Comment = WithTimestamps & {
  readonly __typename?: 'Comment';
  /** The user who created this comment for the parent post. */
  readonly author: Profile;
  /** Unmodified content. */
  readonly content?: Maybe<Scalars['String']['output']>;
  /** Html formatted content. */
  readonly contentFormatted?: Maybe<Scalars['String']['output']>;
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  /** Users who liked this comment */
  readonly likes: ProfileConnection;
  /** The parent comment if this comment was a reply to another. */
  readonly parent?: Maybe<Comment>;
  /** The post that this comment is attached to. */
  readonly post: Post;
  /** Replies to this comment */
  readonly replies: CommentConnection;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** A comment on a post */
export type CommentLikesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<CommentLikeSortOption>>>;
};

/** A comment on a post */
export type CommentRepliesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<CommentSortOption>>>;
};

/** The connection type for Comment. */
export type CommentConnection = {
  readonly __typename?: 'CommentConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<CommentEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Comment>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CommentEdge = {
  readonly __typename?: 'CommentEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Comment>;
};

export enum CommentLikeSortEnum {
  CreatedAt = 'CREATED_AT',
  Following = 'FOLLOWING',
}

export type CommentLikeSortOption = {
  readonly direction: SortDirection;
  readonly on: CommentLikeSortEnum;
};

export enum CommentSortEnum {
  CreatedAt = 'CREATED_AT',
  Following = 'FOLLOWING',
  LikesCount = 'LIKES_COUNT',
}

export type CommentSortOption = {
  readonly direction: SortDirection;
  readonly on: CommentSortEnum;
};

/** An Episode of a Media */
export type Episode = Unit &
  WithTimestamps & {
    readonly __typename?: 'Episode';
    /** The anime this episode is in */
    readonly anime: Anime;
    readonly createdAt: Scalars['ISO8601DateTime']['output'];
    /** A brief summary or description of the unit */
    readonly description: Scalars['Map']['output'];
    readonly id: Scalars['ID']['output'];
    /** The length of the episode in seconds */
    readonly length?: Maybe<Scalars['Int']['output']>;
    /** The sequence number of this unit */
    readonly number: Scalars['Int']['output'];
    /** When this episode aired */
    readonly releasedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /** A thumbnail image for the unit */
    readonly thumbnail?: Maybe<Image>;
    /** The titles for this unit in various locales */
    readonly titles: TitlesList;
    readonly updatedAt: Scalars['ISO8601DateTime']['output'];
  };

/** An Episode of a Media */
export type EpisodeDescriptionArgs = {
  locales?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** The connection type for Episode. */
export type EpisodeConnection = {
  readonly __typename?: 'EpisodeConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<EpisodeEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Episode>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

export type EpisodeCreateInput = {
  readonly description?: InputMaybe<Scalars['Map']['input']>;
  readonly length?: InputMaybe<Scalars['Int']['input']>;
  readonly mediaId: Scalars['ID']['input'];
  readonly mediaType: MediaTypeEnum;
  readonly number: Scalars['Int']['input'];
  readonly releasedAt?: InputMaybe<Scalars['Date']['input']>;
  readonly thumbnailImage?: InputMaybe<Scalars['Upload']['input']>;
  readonly titles: TitlesListInput;
};

/** Autogenerated return type of EpisodeCreate */
export type EpisodeCreatePayload = {
  readonly __typename?: 'EpisodeCreatePayload';
  readonly episode?: Maybe<Episode>;
  readonly errors?: Maybe<ReadonlyArray<Error>>;
};

/** Autogenerated return type of EpisodeDelete */
export type EpisodeDeletePayload = {
  readonly __typename?: 'EpisodeDeletePayload';
  readonly episode?: Maybe<GenericDelete>;
  readonly errors?: Maybe<ReadonlyArray<Error>>;
};

/** An edge in a connection. */
export type EpisodeEdge = {
  readonly __typename?: 'EpisodeEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Episode>;
};

export type EpisodeMutations = {
  readonly __typename?: 'EpisodeMutations';
  /** Create an Episode. */
  readonly create?: Maybe<EpisodeCreatePayload>;
  /** Delete an Episode. */
  readonly delete?: Maybe<EpisodeDeletePayload>;
  /** Update an Episode. */
  readonly update?: Maybe<EpisodeUpdatePayload>;
};

export type EpisodeMutationsCreateArgs = {
  input: EpisodeCreateInput;
};

export type EpisodeMutationsDeleteArgs = {
  input: GenericDeleteInput;
};

export type EpisodeMutationsUpdateArgs = {
  input: EpisodeUpdateInput;
};

export enum EpisodeSortEnum {
  CreatedAt = 'CREATED_AT',
  Number = 'NUMBER',
  UpdatedAt = 'UPDATED_AT',
}

export type EpisodeSortOption = {
  readonly direction: SortDirection;
  readonly on: EpisodeSortEnum;
};

export type EpisodeUpdateInput = {
  readonly description?: InputMaybe<Scalars['Map']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly length?: InputMaybe<Scalars['Int']['input']>;
  readonly number?: InputMaybe<Scalars['Int']['input']>;
  readonly releasedAt?: InputMaybe<Scalars['Date']['input']>;
  readonly thumbnailImage?: InputMaybe<Scalars['Upload']['input']>;
  readonly titles?: InputMaybe<TitlesListInput>;
};

/** Autogenerated return type of EpisodeUpdate */
export type EpisodeUpdatePayload = {
  readonly __typename?: 'EpisodeUpdatePayload';
  readonly episode?: Maybe<Episode>;
  readonly errors?: Maybe<ReadonlyArray<Error>>;
};

/** An episodic media in the Kitsu database */
export type Episodic = {
  /** The number of episodes in this series */
  readonly episodeCount?: Maybe<Scalars['Int']['output']>;
  /** The general length (in seconds) of each episode */
  readonly episodeLength?: Maybe<Scalars['Int']['output']>;
  /** Episodes for this media */
  readonly episodes: EpisodeConnection;
  /** The total length (in seconds) of the entire series */
  readonly totalLength?: Maybe<Scalars['Int']['output']>;
};

/** An episodic media in the Kitsu database */
export type EpisodicEpisodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<EpisodeSortOption>>>;
};

/** Generic error fields used by all errors. */
export type Error = {
  /** The error code. */
  readonly code?: Maybe<Scalars['String']['output']>;
  /** A description of the error */
  readonly message: Scalars['String']['output'];
  /** Which input value this error came from */
  readonly path?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
};

export enum ExternalIdentityProviderEnum {
  /** Facebook identity */
  Facebook = 'FACEBOOK',
}

/** Favorite media, characters, and people for a user */
export type Favorite = WithTimestamps & {
  readonly __typename?: 'Favorite';
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  /** The kitsu object that is mapped */
  readonly item: FavoriteItemUnion;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
  /** The user who favorited this item */
  readonly user: Profile;
};

/** The connection type for Favorite. */
export type FavoriteConnection = {
  readonly __typename?: 'FavoriteConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<FavoriteEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Favorite>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

export type FavoriteCreateErrorsUnion =
  | NotAuthenticatedError
  | NotAuthorizedError
  | NotFoundError;

export type FavoriteCreateInput = {
  /** The id of the entry */
  readonly id: Scalars['ID']['input'];
  /** The type of the entry. */
  readonly type: FavoriteEnum;
};

/** Autogenerated return type of FavoriteCreate */
export type FavoriteCreatePayload = {
  readonly __typename?: 'FavoriteCreatePayload';
  readonly errors?: Maybe<ReadonlyArray<FavoriteCreateErrorsUnion>>;
  readonly result?: Maybe<Favorite>;
};

export type FavoriteDeleteErrorsUnion =
  | NotAuthenticatedError
  | NotAuthorizedError
  | NotFoundError;

export type FavoriteDeleteInput = {
  /** The id of the favorite entry. */
  readonly favoriteId: Scalars['ID']['input'];
};

/** Autogenerated return type of FavoriteDelete */
export type FavoriteDeletePayload = {
  readonly __typename?: 'FavoriteDeletePayload';
  readonly errors?: Maybe<ReadonlyArray<FavoriteDeleteErrorsUnion>>;
  readonly result?: Maybe<Favorite>;
};

/** An edge in a connection. */
export type FavoriteEdge = {
  readonly __typename?: 'FavoriteEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Favorite>;
};

export enum FavoriteEnum {
  Anime = 'ANIME',
  Character = 'CHARACTER',
  Manga = 'MANGA',
  Person = 'PERSON',
}

/** Objects which are Favoritable */
export type FavoriteItemUnion = Anime | Character | Manga | Person;

export type FavoriteMutations = {
  readonly __typename?: 'FavoriteMutations';
  /** Add a favorite entry */
  readonly create?: Maybe<FavoriteCreatePayload>;
  /** Delete a favorite entry */
  readonly delete?: Maybe<FavoriteDeletePayload>;
};

export type FavoriteMutationsCreateArgs = {
  input: FavoriteCreateInput;
};

export type FavoriteMutationsDeleteArgs = {
  input: FavoriteDeleteInput;
};

export enum FollowSortEnum {
  CreatedAt = 'CREATED_AT',
  FollowingFollowed = 'FOLLOWING_FOLLOWED',
  FollowingFollower = 'FOLLOWING_FOLLOWER',
}

export type FollowSortOption = {
  readonly direction: SortDirection;
  readonly on: FollowSortEnum;
};

/** Related media grouped together */
export type Franchise = WithTimestamps & {
  readonly __typename?: 'Franchise';
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  /** All media related to a franchise */
  readonly installments?: Maybe<InstallmentConnection>;
  /** The name of this franchise in various languages */
  readonly titles: TitlesList;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Related media grouped together */
export type FranchiseInstallmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<InstallmentSortOption>>>;
};

/** The connection type for Franchise. */
export type FranchiseConnection = {
  readonly __typename?: 'FranchiseConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<FranchiseEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Franchise>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FranchiseEdge = {
  readonly __typename?: 'FranchiseEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Franchise>;
};

export type GenericDelete = {
  readonly __typename?: 'GenericDelete';
  readonly id: Scalars['ID']['output'];
};

export type GenericDeleteInput = {
  readonly id: Scalars['ID']['input'];
};

export type GenericError = Error & {
  readonly __typename?: 'GenericError';
  /** The error code. */
  readonly code?: Maybe<Scalars['String']['output']>;
  /** A description of the error */
  readonly message: Scalars['String']['output'];
  /** Which input value this error came from */
  readonly path?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
};

export type Image = {
  readonly __typename?: 'Image';
  /** A blurhash-encoded version of this image */
  readonly blurhash?: Maybe<Scalars['String']['output']>;
  /** The original image */
  readonly original: ImageView;
  /** The various generated views of this image */
  readonly views: ReadonlyArray<ImageView>;
};

export type ImageViewsArgs = {
  names?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type ImageView = {
  readonly __typename?: 'ImageView';
  /** The height of the image */
  readonly height?: Maybe<Scalars['Int']['output']>;
  /** The name of this view of the image */
  readonly name: Scalars['String']['output'];
  /** The URL of this view of the image */
  readonly url: Scalars['String']['output'];
  /** The width of the image */
  readonly width?: Maybe<Scalars['Int']['output']>;
};

/** Individual media that belongs to a franchise */
export type Installment = WithTimestamps & {
  readonly __typename?: 'Installment';
  /** Order based chronologically */
  readonly alternativeOrder?: Maybe<Scalars['Int']['output']>;
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  /** The franchise related to this installment */
  readonly franchise: Franchise;
  readonly id: Scalars['ID']['output'];
  /** The media related to this installment */
  readonly media: Media;
  /** Order based by date released */
  readonly releaseOrder?: Maybe<Scalars['Int']['output']>;
  /** Further explains the media relationship corresponding to a franchise */
  readonly tag?: Maybe<InstallmentTagEnum>;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Installment. */
export type InstallmentConnection = {
  readonly __typename?: 'InstallmentConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<InstallmentEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Installment>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InstallmentEdge = {
  readonly __typename?: 'InstallmentEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Installment>;
};

export enum InstallmentSortEnum {
  AlternativeOrder = 'ALTERNATIVE_ORDER',
  ReleaseOrder = 'RELEASE_ORDER',
}

export type InstallmentSortOption = {
  readonly direction: SortDirection;
  readonly on: InstallmentSortEnum;
};

export enum InstallmentTagEnum {
  /** Same universe/world/reality/timeline, completely different characters. */
  AlternateSetting = 'ALTERNATE_SETTING',
  /** Same setting, same characters, story is told differently. */
  AlternateVersion = 'ALTERNATE_VERSION',
  /** Characters from different media meet in the same story. */
  Crossover = 'CROSSOVER',
  /** The main story. */
  MainStory = 'MAIN_STORY',
  /** Takes place sometime during the main storyline. */
  SideStory = 'SIDE_STORY',
  /** Uses characters of a different series, but is not an alternate setting or story. */
  Spinoff = 'SPINOFF',
}

/** The user library filterable by media_type and status */
export type Library = {
  readonly __typename?: 'Library';
  /** All Library Entries for a specific Media */
  readonly all: LibraryEntryConnection;
  /** Library Entries for a specific Media filtered by the completed status */
  readonly completed: LibraryEntryConnection;
  /** Library Entries for a specific Media filtered by the current status */
  readonly current: LibraryEntryConnection;
  /** Library Entries for a specific Media filtered by the dropped status */
  readonly dropped: LibraryEntryConnection;
  /** Library Entries for a specific Media filtered by the on_hold status */
  readonly onHold: LibraryEntryConnection;
  /** Library Entries for a specific Media filtered by the planned status */
  readonly planned: LibraryEntryConnection;
  /** Random anime or manga from this library */
  readonly randomMedia?: Maybe<Media>;
};

/** The user library filterable by media_type and status */
export type LibraryAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mediaType: MediaTypeEnum;
  status?: InputMaybe<ReadonlyArray<LibraryEntryStatusEnum>>;
};

/** The user library filterable by media_type and status */
export type LibraryCompletedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mediaType: MediaTypeEnum;
};

/** The user library filterable by media_type and status */
export type LibraryCurrentArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mediaType: MediaTypeEnum;
};

/** The user library filterable by media_type and status */
export type LibraryDroppedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mediaType: MediaTypeEnum;
};

/** The user library filterable by media_type and status */
export type LibraryOnHoldArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mediaType: MediaTypeEnum;
};

/** The user library filterable by media_type and status */
export type LibraryPlannedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mediaType: MediaTypeEnum;
};

/** The user library filterable by media_type and status */
export type LibraryRandomMediaArgs = {
  mediaType: MediaTypeEnum;
  status: ReadonlyArray<LibraryEntryStatusEnum>;
};

/** Information about a specific media entry for a user */
export type LibraryEntry = WithTimestamps & {
  readonly __typename?: 'LibraryEntry';
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  /** History of user actions for this library entry. */
  readonly events?: Maybe<LibraryEventConnection>;
  /** When the user finished this media. */
  readonly finishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  readonly id: Scalars['ID']['output'];
  /** The last unit consumed */
  readonly lastUnit?: Maybe<Unit>;
  /** The media related to this library entry. */
  readonly media: Media;
  /** The next unit to be consumed */
  readonly nextUnit?: Maybe<Unit>;
  /** Notes left by the profile related to this library entry. */
  readonly notes?: Maybe<Scalars['String']['output']>;
  /** If the media related to the library entry is Not-Safe-for-Work. */
  readonly nsfw: Scalars['Boolean']['output'];
  /** If this library entry is publicly visibile from their profile, or hidden. */
  readonly private: Scalars['Boolean']['output'];
  /** The number of episodes/chapters this user has watched/read */
  readonly progress: Scalars['Int']['output'];
  /** When the user last watched an episode or read a chapter of this media. */
  readonly progressedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** How much you enjoyed this media (lower meaning not liking). */
  readonly rating?: Maybe<Scalars['Int']['output']>;
  /** The reaction based on the media of this library entry. */
  readonly reaction?: Maybe<MediaReaction>;
  /** Amount of times this media has been rewatched. */
  readonly reconsumeCount: Scalars['Int']['output'];
  /** If the profile is currently rewatching this media. */
  readonly reconsuming: Scalars['Boolean']['output'];
  /** When the user started this media. */
  readonly startedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  readonly status: LibraryEntryStatusEnum;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
  /** The user who created this library entry. */
  readonly user: Profile;
  /** Volumes that the profile owns (physically or digital). */
  readonly volumesOwned: Scalars['Int']['output'];
};

/** Information about a specific media entry for a user */
export type LibraryEntryEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mediaTypes?: InputMaybe<ReadonlyArray<MediaTypeEnum>>;
};

/** The connection type for LibraryEntry. */
export type LibraryEntryConnection = {
  readonly __typename?: 'LibraryEntryConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<LibraryEntryEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<LibraryEntry>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

export type LibraryEntryCreateInput = {
  readonly finishedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  readonly mediaId: Scalars['ID']['input'];
  readonly mediaType: MediaTypeEnum;
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly private?: InputMaybe<Scalars['Boolean']['input']>;
  readonly progress?: InputMaybe<Scalars['Int']['input']>;
  readonly rating?: InputMaybe<Scalars['Int']['input']>;
  readonly reconsumeCount?: InputMaybe<Scalars['Int']['input']>;
  readonly reconsuming?: InputMaybe<Scalars['Boolean']['input']>;
  readonly startedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  readonly status: LibraryEntryStatusEnum;
  readonly volumesOwned?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of LibraryEntryCreate */
export type LibraryEntryCreatePayload = {
  readonly __typename?: 'LibraryEntryCreatePayload';
  readonly errors?: Maybe<ReadonlyArray<Error>>;
  readonly libraryEntry?: Maybe<LibraryEntry>;
};

/** Autogenerated return type of LibraryEntryDelete */
export type LibraryEntryDeletePayload = {
  readonly __typename?: 'LibraryEntryDeletePayload';
  readonly errors?: Maybe<ReadonlyArray<Error>>;
  readonly libraryEntry?: Maybe<GenericDelete>;
};

/** An edge in a connection. */
export type LibraryEntryEdge = {
  readonly __typename?: 'LibraryEntryEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<LibraryEntry>;
};

export type LibraryEntryMutations = {
  readonly __typename?: 'LibraryEntryMutations';
  /** Create a library entry */
  readonly create?: Maybe<LibraryEntryCreatePayload>;
  /** Delete a library entry */
  readonly delete?: Maybe<LibraryEntryDeletePayload>;
  /** Update a library entry */
  readonly update?: Maybe<LibraryEntryUpdatePayload>;
  /** Update library entry progress by id */
  readonly updateProgressById?: Maybe<LibraryEntryUpdateProgressByIdPayload>;
  /** Update library entry progress by media */
  readonly updateProgressByMedia?: Maybe<LibraryEntryUpdateProgressByMediaPayload>;
  /** Update library entry rating by id */
  readonly updateRatingById?: Maybe<LibraryEntryUpdateRatingByIdPayload>;
  /** Update library entry rating by media */
  readonly updateRatingByMedia?: Maybe<LibraryEntryUpdateRatingByMediaPayload>;
  /** Update library entry status by id */
  readonly updateStatusById?: Maybe<LibraryEntryUpdateStatusByIdPayload>;
  /** Update library entry status by media */
  readonly updateStatusByMedia?: Maybe<LibraryEntryUpdateStatusByMediaPayload>;
};

export type LibraryEntryMutationsCreateArgs = {
  input: LibraryEntryCreateInput;
};

export type LibraryEntryMutationsDeleteArgs = {
  input: GenericDeleteInput;
};

export type LibraryEntryMutationsUpdateArgs = {
  input: LibraryEntryUpdateInput;
};

export type LibraryEntryMutationsUpdateProgressByIdArgs = {
  input: LibraryEntryUpdateProgressByIdInput;
};

export type LibraryEntryMutationsUpdateProgressByMediaArgs = {
  input: LibraryEntryUpdateProgressByMediaInput;
};

export type LibraryEntryMutationsUpdateRatingByIdArgs = {
  input: LibraryEntryUpdateRatingByIdInput;
};

export type LibraryEntryMutationsUpdateRatingByMediaArgs = {
  input: LibraryEntryUpdateRatingByMediaInput;
};

export type LibraryEntryMutationsUpdateStatusByIdArgs = {
  input: LibraryEntryUpdateStatusByIdInput;
};

export type LibraryEntryMutationsUpdateStatusByMediaArgs = {
  input: LibraryEntryUpdateStatusByMediaInput;
};

export enum LibraryEntryStatusEnum {
  /** The user completed this media. */
  Completed = 'COMPLETED',
  /** The user is currently reading or watching this media. */
  Current = 'CURRENT',
  /** The user started but chose not to finish this media. */
  Dropped = 'DROPPED',
  /** The user started but paused reading or watching this media. */
  OnHold = 'ON_HOLD',
  /** The user plans to read or watch this media in future. */
  Planned = 'PLANNED',
}

export type LibraryEntryUpdateInput = {
  readonly finishedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly private?: InputMaybe<Scalars['Boolean']['input']>;
  readonly progress?: InputMaybe<Scalars['Int']['input']>;
  readonly rating?: InputMaybe<Scalars['Int']['input']>;
  readonly reconsumeCount?: InputMaybe<Scalars['Int']['input']>;
  readonly reconsuming?: InputMaybe<Scalars['Boolean']['input']>;
  readonly startedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  readonly status?: InputMaybe<LibraryEntryStatusEnum>;
  readonly volumesOwned?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of LibraryEntryUpdate */
export type LibraryEntryUpdatePayload = {
  readonly __typename?: 'LibraryEntryUpdatePayload';
  readonly errors?: Maybe<ReadonlyArray<Error>>;
  readonly libraryEntry?: Maybe<LibraryEntry>;
};

export type LibraryEntryUpdateProgressByIdInput = {
  readonly id: Scalars['ID']['input'];
  readonly progress: Scalars['Int']['input'];
};

/** Autogenerated return type of LibraryEntryUpdateProgressById */
export type LibraryEntryUpdateProgressByIdPayload = {
  readonly __typename?: 'LibraryEntryUpdateProgressByIdPayload';
  readonly errors?: Maybe<ReadonlyArray<Error>>;
  readonly libraryEntry?: Maybe<LibraryEntry>;
};

export type LibraryEntryUpdateProgressByMediaInput = {
  readonly mediaId: Scalars['ID']['input'];
  readonly mediaType: MediaTypeEnum;
  readonly progress: Scalars['Int']['input'];
};

/** Autogenerated return type of LibraryEntryUpdateProgressByMedia */
export type LibraryEntryUpdateProgressByMediaPayload = {
  readonly __typename?: 'LibraryEntryUpdateProgressByMediaPayload';
  readonly errors?: Maybe<ReadonlyArray<Error>>;
  readonly libraryEntry?: Maybe<LibraryEntry>;
};

export type LibraryEntryUpdateRatingByIdInput = {
  readonly id: Scalars['ID']['input'];
  /** A number between 2 - 20 */
  readonly rating: Scalars['Int']['input'];
};

/** Autogenerated return type of LibraryEntryUpdateRatingById */
export type LibraryEntryUpdateRatingByIdPayload = {
  readonly __typename?: 'LibraryEntryUpdateRatingByIdPayload';
  readonly errors?: Maybe<ReadonlyArray<Error>>;
  readonly libraryEntry?: Maybe<LibraryEntry>;
};

export type LibraryEntryUpdateRatingByMediaInput = {
  readonly mediaId: Scalars['ID']['input'];
  readonly mediaType: MediaTypeEnum;
  /** A number between 2 - 20 */
  readonly rating: Scalars['Int']['input'];
};

/** Autogenerated return type of LibraryEntryUpdateRatingByMedia */
export type LibraryEntryUpdateRatingByMediaPayload = {
  readonly __typename?: 'LibraryEntryUpdateRatingByMediaPayload';
  readonly errors?: Maybe<ReadonlyArray<Error>>;
  readonly libraryEntry?: Maybe<LibraryEntry>;
};

export type LibraryEntryUpdateStatusByIdInput = {
  readonly id: Scalars['ID']['input'];
  readonly status: LibraryEntryStatusEnum;
};

/** Autogenerated return type of LibraryEntryUpdateStatusById */
export type LibraryEntryUpdateStatusByIdPayload = {
  readonly __typename?: 'LibraryEntryUpdateStatusByIdPayload';
  readonly errors?: Maybe<ReadonlyArray<Error>>;
  readonly libraryEntry?: Maybe<LibraryEntry>;
};

export type LibraryEntryUpdateStatusByMediaInput = {
  readonly mediaId: Scalars['ID']['input'];
  readonly mediaType: MediaTypeEnum;
  readonly status: LibraryEntryStatusEnum;
};

/** Autogenerated return type of LibraryEntryUpdateStatusByMedia */
export type LibraryEntryUpdateStatusByMediaPayload = {
  readonly __typename?: 'LibraryEntryUpdateStatusByMediaPayload';
  readonly errors?: Maybe<ReadonlyArray<Error>>;
  readonly libraryEntry?: Maybe<LibraryEntry>;
};

/** History of user actions for a library entry. */
export type LibraryEvent = WithTimestamps & {
  readonly __typename?: 'LibraryEvent';
  /** The data that was changed for this library event. */
  readonly changedData: Scalars['Map']['output'];
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  /** The type of library event. */
  readonly kind: LibraryEventKindEnum;
  /** The library entry related to this library event. */
  readonly libraryEntry: LibraryEntry;
  /** The media related to this library event. */
  readonly media: Media;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
  /** The user who created this library event */
  readonly user: Profile;
};

/** The connection type for LibraryEvent. */
export type LibraryEventConnection = {
  readonly __typename?: 'LibraryEventConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<LibraryEventEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<LibraryEvent>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LibraryEventEdge = {
  readonly __typename?: 'LibraryEventEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<LibraryEvent>;
};

export enum LibraryEventKindEnum {
  /** Notes were added/updated. */
  Annotated = 'ANNOTATED',
  /** Progress or Time Spent was added/updated. */
  Progressed = 'PROGRESSED',
  /** Rating was added/updated. */
  Rated = 'RATED',
  /** Reaction was added/updated. */
  Reacted = 'REACTED',
  /** Status or Reconsuming was added/updated. */
  Updated = 'UPDATED',
}

export enum LibraryEventSortEnum {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type LibraryEventSortOption = {
  readonly direction: SortDirection;
  readonly on: LibraryEventSortEnum;
};

export enum LockedReasonEnum {
  Closed = 'CLOSED',
  Spam = 'SPAM',
  TooHeated = 'TOO_HEATED',
}

export type Manga = Media &
  WithTimestamps & {
    readonly __typename?: 'Manga';
    /** The recommended minimum age group for this media */
    readonly ageRating?: Maybe<AgeRatingEnum>;
    /** An explanation of why this received the age rating it did */
    readonly ageRatingGuide?: Maybe<Scalars['String']['output']>;
    /** The average rating of this media amongst all Kitsu users */
    readonly averageRating?: Maybe<Scalars['Float']['output']>;
    /** The rank of this media by rating */
    readonly averageRatingRank?: Maybe<Scalars['Int']['output']>;
    /** A large banner image for this media */
    readonly bannerImage?: Maybe<Image>;
    /** A list of categories for this media */
    readonly categories: CategoryConnection;
    /** Get a specific chapter of the manga. */
    readonly chapter?: Maybe<Chapter>;
    /** The number of chapters in this manga. */
    readonly chapterCount?: Maybe<Scalars['Int']['output']>;
    /** The estimated number of chapters in this manga. */
    readonly chapterCountGuess?: Maybe<Scalars['Int']['output']>;
    /** The chapters in the manga. */
    readonly chapters?: Maybe<ChapterConnection>;
    /** The characters who starred in this media */
    readonly characters: MediaCharacterConnection;
    readonly createdAt: Scalars['ISO8601DateTime']['output'];
    /** A brief (mostly spoiler free) summary or description of the media. */
    readonly description: Scalars['Map']['output'];
    /** the day that this media made its final release */
    readonly endDate?: Maybe<Scalars['Date']['output']>;
    /** The number of users with this in their favorites */
    readonly favoritesCount?: Maybe<Scalars['Int']['output']>;
    readonly id: Scalars['ID']['output'];
    /** A list of mappings for this media */
    readonly mappings: MappingConnection;
    /** Your library entry related to this media. */
    readonly myLibraryEntry?: Maybe<LibraryEntry>;
    /** A list of your wiki submissions for this media */
    readonly myWikiSubmissions: WikiSubmissionConnection;
    /** The time of the next release of this media */
    readonly nextRelease?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /** The countries in which the media was originally primarily produced */
    readonly originCountries: ReadonlyArray<Scalars['String']['output']>;
    /** The languages the media was originally produced in */
    readonly originLanguages: ReadonlyArray<Scalars['String']['output']>;
    /**
     * The country in which the media was primarily produced
     * @deprecated Replaced with originCountries and originLanguages
     */
    readonly originalLocale?: Maybe<Scalars['String']['output']>;
    /** The poster image of this media */
    readonly posterImage?: Maybe<Image>;
    /** All posts that tag this media. */
    readonly posts: PostConnection;
    /** The companies which helped to produce this media */
    readonly productions: MediaProductionConnection;
    /** A list of quotes from this media */
    readonly quotes: QuoteConnection;
    /** A list of reactions for this media */
    readonly reactions: MediaReactionConnection;
    /** Whether the media is Safe-for-Work */
    readonly sfw: Scalars['Boolean']['output'];
    /** The URL-friendly identifier of this media */
    readonly slug: Scalars['String']['output'];
    /** The staff members who worked on this media */
    readonly staff: MediaStaffConnection;
    /** The day that this media first released */
    readonly startDate?: Maybe<Scalars['Date']['output']>;
    /** The current releasing status of this media */
    readonly status: ReleaseStatusEnum;
    /** A secondary type for categorizing Manga. */
    readonly subtype: MangaSubtypeEnum;
    /** Description of when this media is expected to release */
    readonly tba?: Maybe<Scalars['String']['output']>;
    /** The titles for this media in various locales */
    readonly titles: TitlesList;
    /** Anime or Manga. */
    readonly type: Scalars['String']['output'];
    readonly updatedAt: Scalars['ISO8601DateTime']['output'];
    /** The number of users with this in their library */
    readonly userCount?: Maybe<Scalars['Int']['output']>;
    /** The rank of this media by popularity */
    readonly userCountRank?: Maybe<Scalars['Int']['output']>;
    /** The number of volumes in this manga. */
    readonly volumeCount?: Maybe<Scalars['Int']['output']>;
    /** The volumes in the manga. */
    readonly volumes?: Maybe<VolumeConnection>;
  };

export type MangaCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<MediaCategorySortOption>>>;
};

export type MangaChapterArgs = {
  number: Scalars['Int']['input'];
};

export type MangaChaptersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<ChapterSortOption>>>;
};

export type MangaCharactersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<MediaCharacterSortOption>>>;
};

export type MangaDescriptionArgs = {
  locales?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type MangaMappingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type MangaMyWikiSubmissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<WikiSubmissionSortOption>>>;
};

export type MangaPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<PostSortOption>>>;
};

export type MangaProductionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type MangaQuotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type MangaReactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<MediaReactionSortOption>>>;
};

export type MangaStaffArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type MangaVolumesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<VolumeSortOption>>>;
};

export type MangaAmountConsumed = AmountConsumed & {
  readonly __typename?: 'MangaAmountConsumed';
  /** Total media completed atleast once. */
  readonly completed: Scalars['Int']['output'];
  readonly id: Scalars['ID']['output'];
  /** Total amount of media. */
  readonly media: Scalars['Int']['output'];
  /** The profile related to the user for this stat. */
  readonly profile: Profile;
  /** Last time we fully recalculated this stat. */
  readonly recalculatedAt: Scalars['ISO8601Date']['output'];
  /** Total progress of library including reconsuming. */
  readonly units: Scalars['Int']['output'];
};

export type MangaCategoryBreakdown = CategoryBreakdown & {
  readonly __typename?: 'MangaCategoryBreakdown';
  /** A Map of category_id -> count for all categories present on the library entries */
  readonly categories: Scalars['Map']['output'];
  readonly id: Scalars['ID']['output'];
  /** The profile related to the user for this stat. */
  readonly profile: Profile;
  /** Last time we fully recalculated this stat. */
  readonly recalculatedAt: Scalars['ISO8601Date']['output'];
  /** The total amount of library entries. */
  readonly total: Scalars['Int']['output'];
};

/** The connection type for Manga. */
export type MangaConnection = {
  readonly __typename?: 'MangaConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<MangaEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Manga>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MangaEdge = {
  readonly __typename?: 'MangaEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Manga>;
};

export enum MangaSubtypeEnum {
  /** Self published work. */
  Doujin = 'DOUJIN',
  Manga = 'MANGA',
  /** Chinese comics produced in China and in the Greater China region. */
  Manhua = 'MANHUA',
  /** A style of South Korean comic books and graphic novels */
  Manhwa = 'MANHWA',
  Novel = 'NOVEL',
  /** Original English Language. */
  Oel = 'OEL',
  Oneshot = 'ONESHOT',
}

/** Media Mappings from External Sites (MAL, Anilist, etc..) to Kitsu. */
export type Mapping = WithTimestamps & {
  readonly __typename?: 'Mapping';
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  /** The ID of the media from the external site. */
  readonly externalId: Scalars['ID']['output'];
  /** The name of the site which kitsu media is being linked from. */
  readonly externalSite: MappingExternalSiteEnum;
  readonly id: Scalars['ID']['output'];
  /** The kitsu object that is mapped. */
  readonly item: MappingItemUnion;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Mapping. */
export type MappingConnection = {
  readonly __typename?: 'MappingConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<MappingEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Mapping>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

export type MappingCreateInput = {
  readonly externalId: Scalars['ID']['input'];
  readonly externalSite: MappingExternalSiteEnum;
  readonly itemId: Scalars['ID']['input'];
  readonly itemType: MappingItemEnum;
};

/** Autogenerated return type of MappingCreate */
export type MappingCreatePayload = {
  readonly __typename?: 'MappingCreatePayload';
  readonly errors?: Maybe<ReadonlyArray<Error>>;
  readonly mapping?: Maybe<Mapping>;
};

/** Autogenerated return type of MappingDelete */
export type MappingDeletePayload = {
  readonly __typename?: 'MappingDeletePayload';
  readonly errors?: Maybe<ReadonlyArray<Error>>;
  readonly mapping?: Maybe<GenericDelete>;
};

/** An edge in a connection. */
export type MappingEdge = {
  readonly __typename?: 'MappingEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Mapping>;
};

export enum MappingExternalSiteEnum {
  Anidb = 'ANIDB',
  AnilistAnime = 'ANILIST_ANIME',
  AnilistManga = 'ANILIST_MANGA',
  Animenewsnetwork = 'ANIMENEWSNETWORK',
  Aozora = 'AOZORA',
  Hulu = 'HULU',
  ImdbEpisodes = 'IMDB_EPISODES',
  Mangaupdates = 'MANGAUPDATES',
  MyanimelistAnime = 'MYANIMELIST_ANIME',
  MyanimelistCharacters = 'MYANIMELIST_CHARACTERS',
  MyanimelistManga = 'MYANIMELIST_MANGA',
  MyanimelistPeople = 'MYANIMELIST_PEOPLE',
  MyanimelistProducers = 'MYANIMELIST_PRODUCERS',
  Mydramalist = 'MYDRAMALIST',
  Thetvdb = 'THETVDB',
  ThetvdbSeason = 'THETVDB_SEASON',
  ThetvdbSeries = 'THETVDB_SERIES',
  Trakt = 'TRAKT',
}

export enum MappingItemEnum {
  Anime = 'ANIME',
  Category = 'CATEGORY',
  Character = 'CHARACTER',
  Episode = 'EPISODE',
  Manga = 'MANGA',
  Person = 'PERSON',
  Producer = 'PRODUCER',
}

/** Objects which are Mappable */
export type MappingItemUnion =
  | Anime
  | Category
  | Character
  | Episode
  | Manga
  | Person
  | Producer;

export type MappingMutations = {
  readonly __typename?: 'MappingMutations';
  /** Create a Mapping */
  readonly create?: Maybe<MappingCreatePayload>;
  /** Delete a Mapping */
  readonly delete?: Maybe<MappingDeletePayload>;
  /** Update a Mapping */
  readonly update?: Maybe<MappingUpdatePayload>;
};

export type MappingMutationsCreateArgs = {
  input: MappingCreateInput;
};

export type MappingMutationsDeleteArgs = {
  input: GenericDeleteInput;
};

export type MappingMutationsUpdateArgs = {
  input: MappingUpdateInput;
};

export type MappingUpdateInput = {
  readonly externalId?: InputMaybe<Scalars['ID']['input']>;
  readonly externalSite?: InputMaybe<MappingExternalSiteEnum>;
  readonly id: Scalars['ID']['input'];
  readonly itemId?: InputMaybe<Scalars['ID']['input']>;
  readonly itemType?: InputMaybe<MappingItemEnum>;
};

/** Autogenerated return type of MappingUpdate */
export type MappingUpdatePayload = {
  readonly __typename?: 'MappingUpdatePayload';
  readonly errors?: Maybe<ReadonlyArray<Error>>;
  readonly mapping?: Maybe<Mapping>;
};

/** A media in the Kitsu database */
export type Media = {
  /** The recommended minimum age group for this media */
  readonly ageRating?: Maybe<AgeRatingEnum>;
  /** An explanation of why this received the age rating it did */
  readonly ageRatingGuide?: Maybe<Scalars['String']['output']>;
  /** The average rating of this media amongst all Kitsu users */
  readonly averageRating?: Maybe<Scalars['Float']['output']>;
  /** The rank of this media by rating */
  readonly averageRatingRank?: Maybe<Scalars['Int']['output']>;
  /** A large banner image for this media */
  readonly bannerImage?: Maybe<Image>;
  /** A list of categories for this media */
  readonly categories: CategoryConnection;
  /** The characters who starred in this media */
  readonly characters: MediaCharacterConnection;
  /** A brief (mostly spoiler free) summary or description of the media. */
  readonly description: Scalars['Map']['output'];
  /** the day that this media made its final release */
  readonly endDate?: Maybe<Scalars['Date']['output']>;
  /** The number of users with this in their favorites */
  readonly favoritesCount?: Maybe<Scalars['Int']['output']>;
  readonly id: Scalars['ID']['output'];
  /** A list of mappings for this media */
  readonly mappings: MappingConnection;
  /** Your library entry related to this media. */
  readonly myLibraryEntry?: Maybe<LibraryEntry>;
  /** A list of your wiki submissions for this media */
  readonly myWikiSubmissions: WikiSubmissionConnection;
  /** The time of the next release of this media */
  readonly nextRelease?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The countries in which the media was originally primarily produced */
  readonly originCountries: ReadonlyArray<Scalars['String']['output']>;
  /** The languages the media was originally produced in */
  readonly originLanguages: ReadonlyArray<Scalars['String']['output']>;
  /**
   * The country in which the media was primarily produced
   * @deprecated Replaced with originCountries and originLanguages
   */
  readonly originalLocale?: Maybe<Scalars['String']['output']>;
  /** The poster image of this media */
  readonly posterImage?: Maybe<Image>;
  /** All posts that tag this media. */
  readonly posts: PostConnection;
  /** The companies which helped to produce this media */
  readonly productions: MediaProductionConnection;
  /** A list of quotes from this media */
  readonly quotes: QuoteConnection;
  /** A list of reactions for this media */
  readonly reactions: MediaReactionConnection;
  /** Whether the media is Safe-for-Work */
  readonly sfw: Scalars['Boolean']['output'];
  /** The URL-friendly identifier of this media */
  readonly slug: Scalars['String']['output'];
  /** The staff members who worked on this media */
  readonly staff: MediaStaffConnection;
  /** The day that this media first released */
  readonly startDate?: Maybe<Scalars['Date']['output']>;
  /** The current releasing status of this media */
  readonly status: ReleaseStatusEnum;
  /** Description of when this media is expected to release */
  readonly tba?: Maybe<Scalars['String']['output']>;
  /** The titles for this media in various locales */
  readonly titles: TitlesList;
  /** Anime or Manga. */
  readonly type: Scalars['String']['output'];
  /** The number of users with this in their library */
  readonly userCount?: Maybe<Scalars['Int']['output']>;
  /** The rank of this media by popularity */
  readonly userCountRank?: Maybe<Scalars['Int']['output']>;
};

/** A media in the Kitsu database */
export type MediaCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<MediaCategorySortOption>>>;
};

/** A media in the Kitsu database */
export type MediaCharactersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<MediaCharacterSortOption>>>;
};

/** A media in the Kitsu database */
export type MediaDescriptionArgs = {
  locales?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** A media in the Kitsu database */
export type MediaMappingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A media in the Kitsu database */
export type MediaMyWikiSubmissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<WikiSubmissionSortOption>>>;
};

/** A media in the Kitsu database */
export type MediaPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<PostSortOption>>>;
};

/** A media in the Kitsu database */
export type MediaProductionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A media in the Kitsu database */
export type MediaQuotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A media in the Kitsu database */
export type MediaReactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<MediaReactionSortOption>>>;
};

/** A media in the Kitsu database */
export type MediaStaffArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum MediaCategorySortEnum {
  Ancestry = 'ANCESTRY',
  CreatedAt = 'CREATED_AT',
}

export type MediaCategorySortOption = {
  readonly direction: SortDirection;
  readonly on: MediaCategorySortEnum;
};

/** Information about a Character starring in a Media */
export type MediaCharacter = WithTimestamps & {
  readonly __typename?: 'MediaCharacter';
  /** The character */
  readonly character: Character;
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  /** The media */
  readonly media: Media;
  /** The role this character had in the media */
  readonly role: CharacterRoleEnum;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
  /** The voices of this character */
  readonly voices?: Maybe<CharacterVoiceConnection>;
};

/** Information about a Character starring in a Media */
export type MediaCharacterVoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<CharacterVoiceSortOption>>>;
};

/** The connection type for MediaCharacter. */
export type MediaCharacterConnection = {
  readonly __typename?: 'MediaCharacterConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<MediaCharacterEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<MediaCharacter>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MediaCharacterEdge = {
  readonly __typename?: 'MediaCharacterEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<MediaCharacter>;
};

export enum MediaCharacterSortEnum {
  CreatedAt = 'CREATED_AT',
  Role = 'ROLE',
  UpdatedAt = 'UPDATED_AT',
}

export type MediaCharacterSortOption = {
  readonly direction: SortDirection;
  readonly on: MediaCharacterSortEnum;
};

/** The connection type for Media. */
export type MediaConnection = {
  readonly __typename?: 'MediaConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<MediaEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Media>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MediaEdge = {
  readonly __typename?: 'MediaEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Media>;
};

/** The role a company played in the creation or localization of a media */
export type MediaProduction = WithTimestamps & {
  readonly __typename?: 'MediaProduction';
  /** The production company */
  readonly company: Producer;
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  /** The media */
  readonly media: Media;
  /** The role this company played */
  readonly role: MediaProductionRoleEnum;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for MediaProduction. */
export type MediaProductionConnection = {
  readonly __typename?: 'MediaProductionConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<MediaProductionEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<MediaProduction>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MediaProductionEdge = {
  readonly __typename?: 'MediaProductionEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<MediaProduction>;
};

export enum MediaProductionRoleEnum {
  Licensor = 'LICENSOR',
  Producer = 'PRODUCER',
  Serialization = 'SERIALIZATION',
  Studio = 'STUDIO',
}

/** A simple review that is 140 characters long expressing how you felt about a media */
export type MediaReaction = WithTimestamps & {
  readonly __typename?: 'MediaReaction';
  /** The author who wrote this reaction. */
  readonly author: Profile;
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  /** Whether you have liked this media reaction */
  readonly hasLiked: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  /** The library entry related to this reaction. */
  readonly libraryEntry: LibraryEntry;
  /** Users that have liked this reaction */
  readonly likes: ProfileConnection;
  /** The media related to this reaction. */
  readonly media: Media;
  /** When this media reaction was written based on media progress. */
  readonly progress: Scalars['Int']['output'];
  /** The reaction text related to a media. */
  readonly reaction: Scalars['String']['output'];
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** A simple review that is 140 characters long expressing how you felt about a media */
export type MediaReactionLikesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<MediaReactionVoteSortOption>>>;
};

/** The connection type for MediaReaction. */
export type MediaReactionConnection = {
  readonly __typename?: 'MediaReactionConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<MediaReactionEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<MediaReaction>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

export type MediaReactionCreateErrorsUnion =
  | NotAuthenticatedError
  | NotAuthorizedError
  | NotFoundError
  | ValidationError;

export type MediaReactionCreateInput = {
  /** The ID of the entry in your library to react to */
  readonly libraryEntryId: Scalars['ID']['input'];
  /** The text of the reaction to the media */
  readonly reaction: Scalars['String']['input'];
};

/** Autogenerated return type of MediaReactionCreate */
export type MediaReactionCreatePayload = {
  readonly __typename?: 'MediaReactionCreatePayload';
  readonly errors?: Maybe<ReadonlyArray<MediaReactionCreateErrorsUnion>>;
  readonly result?: Maybe<MediaReaction>;
};

export type MediaReactionDeleteErrorsUnion =
  | NotAuthenticatedError
  | NotAuthorizedError
  | NotFoundError;

export type MediaReactionDeleteInput = {
  /** The reaction to delete */
  readonly mediaReactionId: Scalars['ID']['input'];
};

/** Autogenerated return type of MediaReactionDelete */
export type MediaReactionDeletePayload = {
  readonly __typename?: 'MediaReactionDeletePayload';
  readonly errors?: Maybe<ReadonlyArray<MediaReactionDeleteErrorsUnion>>;
  readonly result?: Maybe<MediaReaction>;
};

/** An edge in a connection. */
export type MediaReactionEdge = {
  readonly __typename?: 'MediaReactionEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<MediaReaction>;
};

export type MediaReactionLikeErrorsUnion =
  | NotAuthenticatedError
  | NotAuthorizedError
  | NotFoundError;

export type MediaReactionLikeInput = {
  /** The reaction to like */
  readonly mediaReactionId: Scalars['ID']['input'];
};

/** Autogenerated return type of MediaReactionLike */
export type MediaReactionLikePayload = {
  readonly __typename?: 'MediaReactionLikePayload';
  readonly errors?: Maybe<ReadonlyArray<MediaReactionLikeErrorsUnion>>;
  readonly result?: Maybe<MediaReaction>;
};

export type MediaReactionMutations = {
  readonly __typename?: 'MediaReactionMutations';
  /** Share a brief reaction to a media, tied to your library entry */
  readonly create?: Maybe<MediaReactionCreatePayload>;
  /** Delete a mutation */
  readonly delete?: Maybe<MediaReactionDeletePayload>;
  /** Like a media reaction */
  readonly like?: Maybe<MediaReactionLikePayload>;
  /** Remove your like from a media reaction */
  readonly unlike?: Maybe<MediaReactionUnlikePayload>;
};

export type MediaReactionMutationsCreateArgs = {
  input: MediaReactionCreateInput;
};

export type MediaReactionMutationsDeleteArgs = {
  input: MediaReactionDeleteInput;
};

export type MediaReactionMutationsLikeArgs = {
  input: MediaReactionLikeInput;
};

export type MediaReactionMutationsUnlikeArgs = {
  input: MediaReactionUnlikeInput;
};

export enum MediaReactionSortEnum {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
  UpVotesCount = 'UP_VOTES_COUNT',
}

export type MediaReactionSortOption = {
  readonly direction: SortDirection;
  readonly on: MediaReactionSortEnum;
};

export type MediaReactionUnlikeErrorsUnion =
  | NotAuthenticatedError
  | NotAuthorizedError
  | NotFoundError;

export type MediaReactionUnlikeInput = {
  /** The reaction to remove your like from */
  readonly mediaReactionId: Scalars['ID']['input'];
};

/** Autogenerated return type of MediaReactionUnlike */
export type MediaReactionUnlikePayload = {
  readonly __typename?: 'MediaReactionUnlikePayload';
  readonly errors?: Maybe<ReadonlyArray<MediaReactionUnlikeErrorsUnion>>;
  readonly result?: Maybe<MediaReaction>;
};

export enum MediaReactionVoteSortEnum {
  CreatedAt = 'CREATED_AT',
  Following = 'FOLLOWING',
}

export type MediaReactionVoteSortOption = {
  readonly direction: SortDirection;
  readonly on: MediaReactionVoteSortEnum;
};

/** Information about a person working on an anime */
export type MediaStaff = WithTimestamps & {
  readonly __typename?: 'MediaStaff';
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  /** The media */
  readonly media: Media;
  /** The person */
  readonly person: Person;
  /** The role this person had in the creation of this media */
  readonly role: Scalars['String']['output'];
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for MediaStaff. */
export type MediaStaffConnection = {
  readonly __typename?: 'MediaStaffConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<MediaStaffEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<MediaStaff>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MediaStaffEdge = {
  readonly __typename?: 'MediaStaffEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<MediaStaff>;
};

/** これはアニメやマンガです */
export enum MediaTypeEnum {
  Anime = 'ANIME',
  Manga = 'MANGA',
}

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly account: AccountMutations;
  /** Create a new Kitsu account */
  readonly accountCreate?: Maybe<AccountCreatePayload>;
  readonly anime: AnimeMutations;
  readonly block: BlockMutations;
  readonly episode: EpisodeMutations;
  readonly favorite: FavoriteMutations;
  readonly libraryEntry: LibraryEntryMutations;
  readonly mapping: MappingMutations;
  readonly mediaReaction: MediaReactionMutations;
  readonly post: PostMutations;
  readonly pro: ProMutations;
  readonly profile: ProfileMutations;
  readonly profileLink: ProfileLinkMutations;
  readonly wikiSubmission: WikiSubmissionMutations;
};

export type MutationAccountCreateArgs = {
  input: AccountCreateInput;
};

/** The mutation requires an authenticated logged-in user session, and none was provided or the session has expired. The recommended action varies depending on your application and whether you provided the bearer token in the `Authorization` header or not. If you did, you should probably attempt to refresh the token, and if that fails, prompt the user to log in again. If you did not provide a bearer token, you should just prompt the user to log in. */
export type NotAuthenticatedError = Error & {
  readonly __typename?: 'NotAuthenticatedError';
  /** The error code. */
  readonly code?: Maybe<Scalars['String']['output']>;
  /** A description of the error */
  readonly message: Scalars['String']['output'];
  /** Which input value this error came from */
  readonly path?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
};

/** The mutation requires higher permissions than the current user or token has. This is a bit vague, but it generally means you're attempting to modify an object you don't own, or perform an administrator action without being an administrator. It could also mean your token does not have the required scopes to perform the action. The recommended action is to display a message to the user informing them that their action failed and that retrying will generally *not* help. */
export type NotAuthorizedError = Error & {
  readonly __typename?: 'NotAuthorizedError';
  readonly action?: Maybe<Scalars['String']['output']>;
  /** The error code. */
  readonly code?: Maybe<Scalars['String']['output']>;
  /** A description of the error */
  readonly message: Scalars['String']['output'];
  /** Which input value this error came from */
  readonly path?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
};

/** An object required for your mutation was unable to be located. Usually this means the object you're attempting to modify or delete does not exist. The recommended action is to display a message to the user informing them that their action failed and that retrying will generally *not* help. */
export type NotFoundError = Error & {
  readonly __typename?: 'NotFoundError';
  /** The error code. */
  readonly code?: Maybe<Scalars['String']['output']>;
  /** A description of the error */
  readonly message: Scalars['String']['output'];
  /** Which input value this error came from */
  readonly path?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  readonly __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  readonly endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  readonly hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  readonly startCursor?: Maybe<Scalars['String']['output']>;
};

/** A Voice Actor, Director, Animator, or other person who works in the creation and localization of media */
export type Person = WithTimestamps & {
  readonly __typename?: 'Person';
  /** The day when this person was born */
  readonly birthday?: Maybe<Scalars['Date']['output']>;
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  /** A brief biography or description of the person. */
  readonly description: Scalars['Map']['output'];
  readonly id: Scalars['ID']['output'];
  /** An image of the person */
  readonly image?: Maybe<Image>;
  /** Information about the person working on specific media */
  readonly mediaStaff?: Maybe<MediaStaffConnection>;
  /** The primary name of this person. */
  readonly name: Scalars['String']['output'];
  /** The name of this person in various languages */
  readonly names: TitlesList;
  /** The URL-friendly identifier of this person. */
  readonly slug: Scalars['String']['output'];
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
  /** The voice-acting roles this person has had. */
  readonly voices?: Maybe<CharacterVoiceConnection>;
};

/** A Voice Actor, Director, Animator, or other person who works in the creation and localization of media */
export type PersonDescriptionArgs = {
  locales?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** A Voice Actor, Director, Animator, or other person who works in the creation and localization of media */
export type PersonMediaStaffArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A Voice Actor, Director, Animator, or other person who works in the creation and localization of media */
export type PersonVoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A post that is visible to your followers and globally in the news-feed. */
export type Post = WithTimestamps & {
  readonly __typename?: 'Post';
  /** The user who created this post. */
  readonly author: Profile;
  /** All comments on this post */
  readonly comments: CommentConnection;
  /** Unmodified content. */
  readonly content?: Maybe<Scalars['String']['output']>;
  /** Html formatted content. */
  readonly contentFormatted?: Maybe<Scalars['String']['output']>;
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  /** Users that are watching this post */
  readonly follows: ProfileConnection;
  readonly id: Scalars['ID']['output'];
  /** If a post is Not-Safe-for-Work. */
  readonly isNsfw: Scalars['Boolean']['output'];
  /** If this post spoils the tagged media. */
  readonly isSpoiler: Scalars['Boolean']['output'];
  /** Users that have liked this post */
  readonly likes: ProfileConnection;
  /** When this post was locked. */
  readonly lockedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The user who locked this post. */
  readonly lockedBy?: Maybe<Profile>;
  /** The reason why this post was locked. */
  readonly lockedReason?: Maybe<LockedReasonEnum>;
  /** The media tagged in this post. */
  readonly media?: Maybe<Media>;
  /** The profile of the target user of the post. */
  readonly targetProfile: Profile;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** A post that is visible to your followers and globally in the news-feed. */
export type PostCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<CommentSortOption>>>;
};

/** A post that is visible to your followers and globally in the news-feed. */
export type PostFollowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A post that is visible to your followers and globally in the news-feed. */
export type PostLikesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<PostLikeSortOption>>>;
};

/** The connection type for Post. */
export type PostConnection = {
  readonly __typename?: 'PostConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<PostEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Post>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

export type PostCreateInput = {
  readonly content: Scalars['String']['input'];
  readonly isNsfw?: InputMaybe<Scalars['Boolean']['input']>;
  readonly isSpoiler?: InputMaybe<Scalars['Boolean']['input']>;
  readonly mediaId?: InputMaybe<Scalars['ID']['input']>;
  readonly mediaType?: InputMaybe<MediaTypeEnum>;
  readonly spoiledUnitId?: InputMaybe<Scalars['ID']['input']>;
  readonly spoiledUnitType?: InputMaybe<Scalars['String']['input']>;
  readonly targetUserId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of PostCreate */
export type PostCreatePayload = {
  readonly __typename?: 'PostCreatePayload';
  readonly errors?: Maybe<ReadonlyArray<Error>>;
  readonly post?: Maybe<Post>;
};

/** An edge in a connection. */
export type PostEdge = {
  readonly __typename?: 'PostEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Post>;
};

export enum PostLikeSortEnum {
  CreatedAt = 'CREATED_AT',
  Following = 'FOLLOWING',
}

export type PostLikeSortOption = {
  readonly direction: SortDirection;
  readonly on: PostLikeSortEnum;
};

export type PostLockInput = {
  readonly id: Scalars['ID']['input'];
  readonly lockedReason: LockedReasonEnum;
};

/** Autogenerated return type of PostLock */
export type PostLockPayload = {
  readonly __typename?: 'PostLockPayload';
  readonly errors?: Maybe<ReadonlyArray<Error>>;
  readonly post?: Maybe<Post>;
};

export type PostMutations = {
  readonly __typename?: 'PostMutations';
  /** Create a Post. */
  readonly create?: Maybe<PostCreatePayload>;
  /** Lock a Post. */
  readonly lock?: Maybe<PostLockPayload>;
  /** Unlock a Post. */
  readonly unlock?: Maybe<PostUnlockPayload>;
};

export type PostMutationsCreateArgs = {
  input: PostCreateInput;
};

export type PostMutationsLockArgs = {
  input: PostLockInput;
};

export type PostMutationsUnlockArgs = {
  input: PostUnlockInput;
};

export enum PostSortEnum {
  CreatedAt = 'CREATED_AT',
}

export type PostSortOption = {
  readonly direction: SortDirection;
  readonly on: PostSortEnum;
};

export type PostUnlockInput = {
  readonly id: Scalars['ID']['input'];
};

/** Autogenerated return type of PostUnlock */
export type PostUnlockPayload = {
  readonly __typename?: 'PostUnlockPayload';
  readonly errors?: Maybe<ReadonlyArray<Error>>;
  readonly post?: Maybe<Post>;
};

export type ProMutations = {
  readonly __typename?: 'ProMutations';
  /** Set the user's discord tag */
  readonly setDiscord?: Maybe<ProSetDiscordPayload>;
  /** Set the user's Hall-of-Fame message */
  readonly setMessage?: Maybe<ProSetMessagePayload>;
  /** End the user's pro subscription */
  readonly unsubscribe?: Maybe<ProUnsubscribePayload>;
};

export type ProMutationsSetDiscordArgs = {
  discord: Scalars['String']['input'];
};

export type ProMutationsSetMessageArgs = {
  message: Scalars['String']['input'];
};

/** Autogenerated return type of ProSetDiscord */
export type ProSetDiscordPayload = {
  readonly __typename?: 'ProSetDiscordPayload';
  readonly discord: Scalars['String']['output'];
};

/** Autogenerated return type of ProSetMessage */
export type ProSetMessagePayload = {
  readonly __typename?: 'ProSetMessagePayload';
  readonly message: Scalars['String']['output'];
};

/** A subscription to Kitsu PRO */
export type ProSubscription = WithTimestamps & {
  readonly __typename?: 'ProSubscription';
  /** The account which is subscribed to Pro benefits */
  readonly account: Account;
  /** The billing service used for this subscription */
  readonly billingService: RecurringBillingServiceEnum;
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  /** The tier of Pro the account is subscribed to */
  readonly tier: ProTierEnum;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum ProTierEnum {
  /**
   * Aozora Pro (only hides ads)
   * @deprecated No longer for sale
   */
  AoPro = 'AO_PRO',
  /**
   * Aozora Pro+ (only hides ads)
   * @deprecated No longer for sale
   */
  AoProPlus = 'AO_PRO_PLUS',
  /** Top tier of Kitsu Pro */
  Patron = 'PATRON',
  /** Basic tier of Kitsu Pro */
  Pro = 'PRO',
}

/** Autogenerated return type of ProUnsubscribe */
export type ProUnsubscribePayload = {
  readonly __typename?: 'ProUnsubscribePayload';
  readonly expiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** A company involved in the creation or localization of media */
export type Producer = WithTimestamps & {
  readonly __typename?: 'Producer';
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  /** The name of this production company */
  readonly name: Scalars['String']['output'];
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** A user profile on Kitsu */
export type Profile = WithTimestamps & {
  readonly __typename?: 'Profile';
  /** A short biographical blurb about this profile */
  readonly about?: Maybe<Scalars['String']['output']>;
  /** An avatar image to easily identify this profile */
  readonly avatarImage?: Maybe<Image>;
  /** A banner to display at the top of the profile */
  readonly bannerImage?: Maybe<Image>;
  /** When the user was born */
  readonly birthday?: Maybe<Scalars['ISO8601Date']['output']>;
  /** All comments to any post this user has made. */
  readonly comments: CommentConnection;
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  /** Favorite media, characters, and people */
  readonly favorites: FavoriteConnection;
  /** People that follow the user */
  readonly followers: ProfileConnection;
  /** People the user is following */
  readonly following: ProfileConnection;
  /** What the user identifies as */
  readonly gender?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  /** The user library of their media */
  readonly library: Library;
  /** A list of library events for this user */
  readonly libraryEvents: LibraryEventConnection;
  /** The user's general location */
  readonly location?: Maybe<Scalars['String']['output']>;
  /** Media reactions written by this user. */
  readonly mediaReactions: MediaReactionConnection;
  /** A non-unique publicly visible name for the profile. Minimum of 3 characters and any valid Unicode character */
  readonly name: Scalars['String']['output'];
  /** Post pinned to the user profile */
  readonly pinnedPost?: Maybe<Post>;
  /** All posts this profile has made. */
  readonly posts: PostConnection;
  /** The message this user has submitted to the Hall of Fame */
  readonly proMessage?: Maybe<Scalars['String']['output']>;
  /** The PRO level the user currently has */
  readonly proTier?: Maybe<ProTierEnum>;
  /** Reviews created by this user */
  readonly reviews?: Maybe<ReviewConnection>;
  /** Links to the user on other (social media) sites. */
  readonly siteLinks?: Maybe<SiteLinkConnection>;
  /** The URL-friendly identifier for this profile */
  readonly slug?: Maybe<Scalars['String']['output']>;
  /** The different stats we calculate for this user. */
  readonly stats: ProfileStats;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
  /** A fully qualified URL to the profile */
  readonly url?: Maybe<Scalars['String']['output']>;
  /** The character this profile has declared as their waifu or husbando */
  readonly waifu?: Maybe<Character>;
  /** The properly-gendered term for the user's waifu. This should normally only be 'Waifu' or 'Husbando' but some people are jerks, including the person who wrote this... */
  readonly waifuOrHusbando?: Maybe<Scalars['String']['output']>;
  /** Wiki submissions created by this user */
  readonly wikiSubmissions: WikiSubmissionConnection;
};

/** A user profile on Kitsu */
export type ProfileCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A user profile on Kitsu */
export type ProfileFavoritesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A user profile on Kitsu */
export type ProfileFollowersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<FollowSortOption>>>;
};

/** A user profile on Kitsu */
export type ProfileFollowingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<FollowSortOption>>>;
};

/** A user profile on Kitsu */
export type ProfileLibraryEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<ReadonlyArray<LibraryEventKindEnum>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<LibraryEventSortOption>>>;
};

/** A user profile on Kitsu */
export type ProfileMediaReactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<MediaReactionSortOption>>>;
};

/** A user profile on Kitsu */
export type ProfilePostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<PostSortOption>>>;
};

/** A user profile on Kitsu */
export type ProfileReviewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<WikiSubmissionSortOption>>>;
};

/** A user profile on Kitsu */
export type ProfileSiteLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A user profile on Kitsu */
export type ProfileWikiSubmissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<WikiSubmissionSortOption>>>;
  statuses?: InputMaybe<ReadonlyArray<WikiSubmissionStatusEnum>>;
};

/** The connection type for Profile. */
export type ProfileConnection = {
  readonly __typename?: 'ProfileConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<ProfileEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Profile>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ProfileEdge = {
  readonly __typename?: 'ProfileEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Profile>;
};

export type ProfileLinkCreateErrorsUnion =
  | NotAuthenticatedError
  | NotAuthorizedError
  | NotFoundError
  | ValidationError;

export type ProfileLinkCreateInput = {
  /** The website. */
  readonly profileLinkSite: ProfileLinksSitesEnum;
  /** The url of the profile link */
  readonly url: Scalars['String']['input'];
};

/** Autogenerated return type of ProfileLinkCreate */
export type ProfileLinkCreatePayload = {
  readonly __typename?: 'ProfileLinkCreatePayload';
  readonly errors?: Maybe<ReadonlyArray<ProfileLinkCreateErrorsUnion>>;
  readonly result?: Maybe<SiteLink>;
};

export type ProfileLinkDeleteErrorsUnion =
  | NotAuthenticatedError
  | NotAuthorizedError
  | NotFoundError;

export type ProfileLinkDeleteInput = {
  /** The profile link to delete */
  readonly profileLink: ProfileLinksSitesEnum;
};

/** Autogenerated return type of ProfileLinkDelete */
export type ProfileLinkDeletePayload = {
  readonly __typename?: 'ProfileLinkDeletePayload';
  readonly errors?: Maybe<ReadonlyArray<ProfileLinkDeleteErrorsUnion>>;
  readonly result?: Maybe<SiteLink>;
};

export type ProfileLinkMutations = {
  readonly __typename?: 'ProfileLinkMutations';
  /** Add a profile link. */
  readonly create?: Maybe<ProfileLinkCreatePayload>;
  /** Delete a profile link. */
  readonly delete?: Maybe<ProfileLinkDeletePayload>;
  /** Update a profile link. */
  readonly update?: Maybe<ProfileLinkUpdatePayload>;
};

export type ProfileLinkMutationsCreateArgs = {
  input: ProfileLinkCreateInput;
};

export type ProfileLinkMutationsDeleteArgs = {
  input: ProfileLinkDeleteInput;
};

export type ProfileLinkMutationsUpdateArgs = {
  input: ProfileLinkUpdateInput;
};

/** An external site that can be linked to a user. */
export type ProfileLinkSite = WithTimestamps & {
  readonly __typename?: 'ProfileLinkSite';
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  /** Name of the external profile website. */
  readonly name: Scalars['String']['output'];
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Regex pattern used to validate the profile link. */
  readonly validateFind: Scalars['String']['output'];
  /** Pattern to be replaced after validation. */
  readonly validateReplace: Scalars['String']['output'];
};

export type ProfileLinkUpdateErrorsUnion =
  | NotAuthenticatedError
  | NotAuthorizedError
  | NotFoundError
  | ValidationError;

export type ProfileLinkUpdateInput = {
  /** The website. */
  readonly profileLinkSite: ProfileLinksSitesEnum;
  /** The url of the profile link */
  readonly url: Scalars['String']['input'];
};

/** Autogenerated return type of ProfileLinkUpdate */
export type ProfileLinkUpdatePayload = {
  readonly __typename?: 'ProfileLinkUpdatePayload';
  readonly errors?: Maybe<ReadonlyArray<ProfileLinkUpdateErrorsUnion>>;
  readonly result?: Maybe<SiteLink>;
};

export enum ProfileLinksSitesEnum {
  Battlenet = 'BATTLENET',
  Dailymotion = 'DAILYMOTION',
  Deviantart = 'DEVIANTART',
  Discord = 'DISCORD',
  Dribbble = 'DRIBBBLE',
  Facebook = 'FACEBOOK',
  Github = 'GITHUB',
  Google = 'GOOGLE',
  Imdb = 'IMDB',
  Instagram = 'INSTAGRAM',
  Kickstarter = 'KICKSTARTER',
  Lastfm = 'LASTFM',
  Letterboxd = 'LETTERBOXD',
  Medium = 'MEDIUM',
  Mobcrush = 'MOBCRUSH',
  Osu = 'OSU',
  Patreon = 'PATREON',
  Playerme = 'PLAYERME',
  Raptr = 'RAPTR',
  Reddit = 'REDDIT',
  Soundcloud = 'SOUNDCLOUD',
  Steam = 'STEAM',
  Trakt = 'TRAKT',
  Tumblr = 'TUMBLR',
  Twitch = 'TWITCH',
  Twitter = 'TWITTER',
  Vimeo = 'VIMEO',
  Website = 'WEBSITE',
  Youtube = 'YOUTUBE',
}

export type ProfileMutations = {
  readonly __typename?: 'ProfileMutations';
  /** Update the profile of the current user. */
  readonly update?: Maybe<ProfileUpdatePayload>;
};

export type ProfileMutationsUpdateArgs = {
  input: ProfileUpdateInput;
};

/** The different types of user stats that we calculate. */
export type ProfileStats = {
  readonly __typename?: 'ProfileStats';
  /** The total amount of anime you have watched over your whole life. */
  readonly animeAmountConsumed: AnimeAmountConsumed;
  /** The breakdown of the different categories related to the anime you have completed */
  readonly animeCategoryBreakdown: AnimeCategoryBreakdown;
  /** The total amount of manga you ahve read over your whole life. */
  readonly mangaAmountConsumed: MangaAmountConsumed;
  /** The breakdown of the different categories related to the manga you have completed */
  readonly mangaCategoryBreakdown: MangaCategoryBreakdown;
};

export type ProfileUpdateErrorsUnion =
  | NotAuthenticatedError
  | NotAuthorizedError
  | NotFoundError;

export type ProfileUpdateInput = {
  /** About section of the profile. */
  readonly about?: InputMaybe<Scalars['String']['input']>;
  /** The birthday of the user. */
  readonly birthday?: InputMaybe<Scalars['Date']['input']>;
  /** The preferred gender of the user. */
  readonly gender?: InputMaybe<Scalars['String']['input']>;
  /** Your ID or the one of another user. */
  readonly id?: InputMaybe<Scalars['ID']['input']>;
  /** The display name of the user */
  readonly name?: InputMaybe<Scalars['String']['input']>;
  /** The slug (@username) of the user */
  readonly slug?: InputMaybe<Scalars['String']['input']>;
  /** The id of the waifu or husbando. */
  readonly waifuId?: InputMaybe<Scalars['ID']['input']>;
  /** The user preference of their partner. */
  readonly waifuOrHusbando?: InputMaybe<WaifuOrHusbandoEnum>;
};

/** Autogenerated return type of ProfileUpdate */
export type ProfileUpdatePayload = {
  readonly __typename?: 'ProfileUpdatePayload';
  readonly errors?: Maybe<ReadonlyArray<ProfileUpdateErrorsUnion>>;
  readonly result?: Maybe<Profile>;
};

export type Query = {
  readonly __typename?: 'Query';
  /** All Anime in the Kitsu database */
  readonly anime: AnimeConnection;
  /** All Anime with specific Status */
  readonly animeByStatus?: Maybe<AnimeConnection>;
  /** All blocked user of the current account. */
  readonly blocks?: Maybe<BlockConnection>;
  /** All Categories in the Kitsu Database */
  readonly categories?: Maybe<CategoryConnection>;
  /** Kitsu account details. You must supply an Authorization token in header. */
  readonly currentAccount?: Maybe<Account>;
  /** Your Kitsu profile. You must supply an Authorization token in header. */
  readonly currentProfile?: Maybe<Profile>;
  /** Find a single Anime by ID */
  readonly findAnimeById?: Maybe<Anime>;
  /** Find a single Anime by Slug */
  readonly findAnimeBySlug?: Maybe<Anime>;
  /** Find a single Category by ID */
  readonly findCategoryById?: Maybe<Category>;
  /** Find a single Category by Slug */
  readonly findCategoryBySlug?: Maybe<Category>;
  /** Find a single Manga Chapter by ID */
  readonly findChapterById?: Maybe<Chapter>;
  /** Find a single Character by ID */
  readonly findCharacterById?: Maybe<Character>;
  /** Find a single Character by Slug */
  readonly findCharacterBySlug?: Maybe<Character>;
  /** Find a single Library Entry by ID */
  readonly findLibraryEntryById?: Maybe<LibraryEntry>;
  /** Find a single Library Event by ID */
  readonly findLibraryEventById?: Maybe<LibraryEvent>;
  /** Find a single Manga by ID */
  readonly findMangaById?: Maybe<Manga>;
  /** Find a single Manga by Slug */
  readonly findMangaBySlug?: Maybe<Manga>;
  /** Find a single Media by ID and Type */
  readonly findMediaByIdAndType?: Maybe<Media>;
  /** Find a single Person by ID */
  readonly findPersonById?: Maybe<Person>;
  /** Find a single Person by Slug */
  readonly findPersonBySlug?: Maybe<Person>;
  /** Find a single Post by ID */
  readonly findPostById?: Maybe<Post>;
  /** Find a single User by ID */
  readonly findProfileById?: Maybe<Profile>;
  /** Find a single User by Slug */
  readonly findProfileBySlug?: Maybe<Profile>;
  /** Find a single Report by ID */
  readonly findReportById?: Maybe<Report>;
  /** Find a single Wiki Submission by ID */
  readonly findWikiSubmissionById?: Maybe<WikiSubmission>;
  /** All Franchise in the Kitsu database */
  readonly franchises?: Maybe<FranchiseConnection>;
  /** List trending media on Kitsu */
  readonly globalTrending: MediaConnection;
  /** List of Library Entries by MediaType and MediaId */
  readonly libraryEntriesByMedia?: Maybe<LibraryEntryConnection>;
  /** List of Library Entries by MediaType */
  readonly libraryEntriesByMediaType?: Maybe<LibraryEntryConnection>;
  /** List trending media within your network */
  readonly localTrending: MediaConnection;
  /** Find a specific Mapping Item by External ID and External Site. */
  readonly lookupMapping?: Maybe<MappingItemUnion>;
  /** All Manga in the Kitsu database */
  readonly manga: MangaConnection;
  /** All Manga with specific Status */
  readonly mangaByStatus?: Maybe<MangaConnection>;
  /** Patrons sorted by a Proprietary Magic Algorithm */
  readonly patrons: ProfileConnection;
  /** Random anime or manga */
  readonly randomMedia: Media;
  /** All Reports in the Kitsu database */
  readonly reports?: Maybe<ReportConnection>;
  /** Select all Reports that match with a supplied status. */
  readonly reportsByStatus?: Maybe<ReportConnection>;
  /** Search for Anime by title using Algolia. The most relevant results will be at the top. */
  readonly searchAnimeByTitle: AnimeConnection;
  /** Search for Manga by title using Algolia. The most relevant results will be at the top. */
  readonly searchMangaByTitle: MangaConnection;
  /** Search for any media (Anime, Manga) by title using Algolia. If no media_type is supplied, it will search for both. The most relevant results will be at the top. */
  readonly searchMediaByTitle: MediaConnection;
  /** Search for User by username using Algolia. The most relevant results will be at the top. */
  readonly searchProfileByUsername?: Maybe<ProfileConnection>;
  /** Get your current session info */
  readonly session: Session;
  /** Select all Wiki Submissions that match with a supplied status. */
  readonly wikiSubmissionsByStatuses?: Maybe<WikiSubmissionConnection>;
};

export type QueryAnimeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryAnimeByStatusArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  status: ReleaseStatusEnum;
};

export type QueryBlocksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryFindAnimeByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFindAnimeBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryFindCategoryByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFindCategoryBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryFindChapterByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFindCharacterByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFindCharacterBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryFindLibraryEntryByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFindLibraryEventByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFindMangaByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFindMangaBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryFindMediaByIdAndTypeArgs = {
  id: Scalars['ID']['input'];
  mediaType: MediaTypeEnum;
};

export type QueryFindPersonByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFindPersonBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryFindPostByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFindProfileByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFindProfileBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryFindReportByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFindWikiSubmissionByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFranchisesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGlobalTrendingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mediaType: MediaTypeEnum;
};

export type QueryLibraryEntriesByMediaArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mediaId: Scalars['ID']['input'];
  mediaType: MediaTypeEnum;
};

export type QueryLibraryEntriesByMediaTypeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mediaType: MediaTypeEnum;
};

export type QueryLocalTrendingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mediaType: MediaTypeEnum;
};

export type QueryLookupMappingArgs = {
  externalId: Scalars['ID']['input'];
  externalSite: MappingExternalSiteEnum;
};

export type QueryMangaArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryMangaByStatusArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  status: ReleaseStatusEnum;
};

export type QueryPatronsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryRandomMediaArgs = {
  ageRatings: ReadonlyArray<AgeRatingEnum>;
  mediaType: MediaTypeEnum;
};

export type QueryReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryReportsByStatusArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<ReadonlyArray<ReportStatusEnum>>;
};

export type QuerySearchAnimeByTitleArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type QuerySearchMangaByTitleArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type QuerySearchMediaByTitleArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mediaType?: InputMaybe<MediaTypeEnum>;
  title: Scalars['String']['input'];
};

export type QuerySearchProfileByUsernameArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  username: Scalars['String']['input'];
};

export type QueryWikiSubmissionsByStatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<InputMaybe<WikiSubmissionSortOption>>>;
  statuses?: InputMaybe<ReadonlyArray<WikiSubmissionStatusEnum>>;
};

/** A quote from a media */
export type Quote = WithTimestamps & {
  readonly __typename?: 'Quote';
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  /** The lines of the quote */
  readonly lines: QuoteLineConnection;
  /** The media this quote is excerpted from */
  readonly media: Media;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** A quote from a media */
export type QuoteLinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Quote. */
export type QuoteConnection = {
  readonly __typename?: 'QuoteConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<QuoteEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Quote>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type QuoteEdge = {
  readonly __typename?: 'QuoteEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Quote>;
};

/** A line in a quote */
export type QuoteLine = WithTimestamps & {
  readonly __typename?: 'QuoteLine';
  /** The character who said this line */
  readonly character: Character;
  /** The line that was spoken */
  readonly content: Scalars['String']['output'];
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  /** The quote this line is in */
  readonly quote: Quote;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for QuoteLine. */
export type QuoteLineConnection = {
  readonly __typename?: 'QuoteLineConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<QuoteLineEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<QuoteLine>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type QuoteLineEdge = {
  readonly __typename?: 'QuoteLineEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<QuoteLine>;
};

export enum RatingSystemEnum {
  /** 1-20 in increments of 1 displayed as 1-10 in 0.5 increments */
  Advanced = 'ADVANCED',
  /** 1-20 in increments of 2 displayed as 5 stars in 0.5 star increments */
  Regular = 'REGULAR',
  /** 1-20 displayed as 4 smileys - Awful (1), Meh (8), Good (14) and Great (20) */
  Simple = 'SIMPLE',
}

export enum RecurringBillingServiceEnum {
  /** Billed through Apple In-App Subscription */
  Apple = 'APPLE',
  /** Billed through Google Play Subscription */
  GooglePlay = 'GOOGLE_PLAY',
  /** Bill a PayPal account */
  Paypal = 'PAYPAL',
  /** Bill a credit card via Stripe */
  Stripe = 'STRIPE',
}

export enum ReleaseSeasonEnum {
  /** Released during the Fall season */
  Fall = 'FALL',
  /** Released during the Spring season */
  Spring = 'SPRING',
  /** Released during the Summer season */
  Summer = 'SUMMER',
  /** Released during the Winter season */
  Winter = 'WINTER',
}

export enum ReleaseStatusEnum {
  /** This media is currently releasing */
  Current = 'CURRENT',
  /** This media is no longer releasing */
  Finished = 'FINISHED',
  /** The release date has not been announced yet */
  Tba = 'TBA',
  /** This media is not released yet */
  Unreleased = 'UNRELEASED',
  /** This media is releasing soon */
  Upcoming = 'UPCOMING',
}

/** A report made by a user */
export type Report = WithTimestamps & {
  readonly __typename?: 'Report';
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  /** Additional information related to why the report was made */
  readonly explanation?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  /** The moderator who responded to this report */
  readonly moderator?: Maybe<Profile>;
  /** The entity that the report is related to */
  readonly naughty?: Maybe<ReportItemUnion>;
  /** The reason for why the report was made */
  readonly reason: ReportReasonEnum;
  /** The user who made this report */
  readonly reporter: Profile;
  /** The resolution status for this report */
  readonly status: ReportStatusEnum;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Report. */
export type ReportConnection = {
  readonly __typename?: 'ReportConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<ReportEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Report>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReportEdge = {
  readonly __typename?: 'ReportEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Report>;
};

/** Objects which are Reportable */
export type ReportItemUnion = Comment | MediaReaction | Post | Review;

export enum ReportReasonEnum {
  /** No bulli! */
  Bullying = 'BULLYING',
  /** Not Safe For Work */
  Nsfw = 'NSFW',
  Offensive = 'OFFENSIVE',
  Other = 'OTHER',
  Spam = 'SPAM',
  Spoiler = 'SPOILER',
}

export enum ReportStatusEnum {
  Declined = 'DECLINED',
  Reported = 'REPORTED',
  Resolved = 'RESOLVED',
}

/** A media review made by a user */
export type Review = WithTimestamps & {
  readonly __typename?: 'Review';
  /** The author who wrote this review. */
  readonly author: Profile;
  /** The review data */
  readonly content: Scalars['String']['output'];
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  /** The review data formatted */
  readonly formattedContent: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  /** Does this review contain spoilers from the media */
  readonly isSpoiler: Scalars['Boolean']['output'];
  /** The library entry related to this review. */
  readonly libraryEntry: LibraryEntry;
  /** Users who liked this review */
  readonly likes: ProfileConnection;
  /** The media related to this review. */
  readonly media: Media;
  /** When this review was written based on media progress. */
  readonly progress: Scalars['Int']['output'];
  /** The user rating for this media */
  readonly rating: Scalars['Int']['output'];
  /** Potentially migrated over from hummingbird. */
  readonly source: Scalars['String']['output'];
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** A media review made by a user */
export type ReviewLikesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Review. */
export type ReviewConnection = {
  readonly __typename?: 'ReviewConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<ReviewEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Review>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReviewEdge = {
  readonly __typename?: 'ReviewEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Review>;
};

/** Information about a user session */
export type Session = {
  readonly __typename?: 'Session';
  /** The account associated with this session */
  readonly account?: Maybe<Account>;
  /** Single sign-on token for Nolt */
  readonly noltToken: Scalars['String']['output'];
  /** The profile associated with this session */
  readonly profile?: Maybe<Profile>;
};

export enum SfwFilterPreferenceEnum {
  /** Adult Content Everywhere */
  NsfwEverywhere = 'NSFW_EVERYWHERE',
  /** Limit to Following Feed */
  NsfwSometimes = 'NSFW_SOMETIMES',
  /** Hide All Adult Content */
  Sfw = 'SFW',
}

/** A link to a user's profile on an external site. */
export type SiteLink = WithTimestamps & {
  readonly __typename?: 'SiteLink';
  /** The user profile the site is linked to. */
  readonly author: Profile;
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  /** The actual linked website. */
  readonly site: ProfileLinkSite;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
  /** A fully qualified URL of the user profile on an external site. */
  readonly url: Scalars['String']['output'];
};

/** The connection type for SiteLink. */
export type SiteLinkConnection = {
  readonly __typename?: 'SiteLinkConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<SiteLinkEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<SiteLink>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SiteLinkEdge = {
  readonly __typename?: 'SiteLinkEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<SiteLink>;
};

export enum SitePermissionEnum {
  /** Administrator/staff member of Kitsu */
  Admin = 'ADMIN',
  /** Moderator of community behavior */
  CommunityMod = 'COMMUNITY_MOD',
  /** Maintainer of the Kitsu media database */
  DatabaseMod = 'DATABASE_MOD',
}

export enum SiteThemeEnum {
  /** Dark Theme */
  Dark = 'DARK',
  /** Light Theme */
  Light = 'LIGHT',
}

export enum SortDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

/** Media that is streamable. */
export type Streamable = {
  /** Spoken language is replaced by language of choice. */
  readonly dubs: ReadonlyArray<Scalars['String']['output']>;
  /** Which regions this video is available in. */
  readonly regions: ReadonlyArray<Scalars['String']['output']>;
  /** The site that is streaming this media. */
  readonly streamer: Streamer;
  /** Languages this is translated to. Usually placed at bottom of media. */
  readonly subs: ReadonlyArray<Scalars['String']['output']>;
};

/** The streaming company. */
export type Streamer = WithTimestamps & {
  readonly __typename?: 'Streamer';
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  /** The name of the site that is streaming this media. */
  readonly siteName: Scalars['String']['output'];
  /** Additional media this site is streaming. */
  readonly streamingLinks: StreamingLinkConnection;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Videos of the media being streamed. */
  readonly videos: VideoConnection;
};

/** The streaming company. */
export type StreamerStreamingLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The streaming company. */
export type StreamerVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The stream link. */
export type StreamingLink = Streamable &
  WithTimestamps & {
    readonly __typename?: 'StreamingLink';
    readonly createdAt: Scalars['ISO8601DateTime']['output'];
    /** Spoken language is replaced by language of choice. */
    readonly dubs: ReadonlyArray<Scalars['String']['output']>;
    readonly id: Scalars['ID']['output'];
    /** The media being streamed */
    readonly media: Media;
    /** Which regions this video is available in. */
    readonly regions: ReadonlyArray<Scalars['String']['output']>;
    /** The site that is streaming this media. */
    readonly streamer: Streamer;
    /** Languages this is translated to. Usually placed at bottom of media. */
    readonly subs: ReadonlyArray<Scalars['String']['output']>;
    readonly updatedAt: Scalars['ISO8601DateTime']['output'];
    /** Fully qualified URL for the streaming link. */
    readonly url: Scalars['String']['output'];
  };

/** The connection type for StreamingLink. */
export type StreamingLinkConnection = {
  readonly __typename?: 'StreamingLinkConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<StreamingLinkEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<StreamingLink>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type StreamingLinkEdge = {
  readonly __typename?: 'StreamingLinkEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<StreamingLink>;
};

export enum TitleLanguagePreferenceEnum {
  /** Prefer the most commonly-used title for media */
  Canonical = 'CANONICAL',
  /** Prefer the localized title for media */
  Localized = 'LOCALIZED',
  /** Prefer the romanized title for media */
  Romanized = 'ROMANIZED',
}

export type TitlesList = {
  readonly __typename?: 'TitlesList';
  /** A list of additional, alternative, abbreviated, or unofficial titles */
  readonly alternatives?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  /** The official or de facto international title */
  readonly canonical: Scalars['String']['output'];
  /** The locale code that identifies which title is used as the canonical title */
  readonly canonicalLocale?: Maybe<Scalars['String']['output']>;
  /** The list of localized titles keyed by locale */
  readonly localized: Scalars['Map']['output'];
  /** The original title of the media in the original language */
  readonly original?: Maybe<Scalars['String']['output']>;
  /** The locale code that identifies which title is used as the original title */
  readonly originalLocale?: Maybe<Scalars['String']['output']>;
  /** The title that best matches the user's preferred settings */
  readonly preferred: Scalars['String']['output'];
  /** The original title, romanized into latin script */
  readonly romanized?: Maybe<Scalars['String']['output']>;
  /** The locale code that identifies which title is used as the romanized title */
  readonly romanizedLocale?: Maybe<Scalars['String']['output']>;
  /** The title translated into the user's locale */
  readonly translated?: Maybe<Scalars['String']['output']>;
  /** The locale code that identifies which title is used as the translated title */
  readonly translatedLocale?: Maybe<Scalars['String']['output']>;
};

export type TitlesListLocalizedArgs = {
  locales?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type TitlesListInput = {
  readonly alternatives?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly canonical?: InputMaybe<Scalars['String']['input']>;
  readonly canonicalLocale?: InputMaybe<Scalars['String']['input']>;
  readonly localized?: InputMaybe<Scalars['Map']['input']>;
};

/** Media units such as episodes or chapters */
export type Unit = {
  /** A brief summary or description of the unit */
  readonly description: Scalars['Map']['output'];
  readonly id: Scalars['ID']['output'];
  /** The sequence number of this unit */
  readonly number: Scalars['Int']['output'];
  /** A thumbnail image for the unit */
  readonly thumbnail?: Maybe<Image>;
  /** The titles for this unit in various locales */
  readonly titles: TitlesList;
};

/** Media units such as episodes or chapters */
export type UnitDescriptionArgs = {
  locales?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** The mutation failed validation. This is usually because the input provided was invalid in some way, such as a missing required field or an invalid value for a field. There may be multiple of this error, one for each failed validation, and the `path` will generally refer to a location in the input parameters, that you can map back to the input fields in your form. The recommended action is to display validation errors to the user, and allow them to correct the input and resubmit. */
export type ValidationError = Error & {
  readonly __typename?: 'ValidationError';
  /** The error code. */
  readonly code?: Maybe<Scalars['String']['output']>;
  /** A description of the error */
  readonly message: Scalars['String']['output'];
  /** Which input value this error came from */
  readonly path?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
};

/** The media video. */
export type Video = Streamable &
  WithTimestamps & {
    readonly __typename?: 'Video';
    readonly createdAt: Scalars['ISO8601DateTime']['output'];
    /** Spoken language is replaced by language of choice. */
    readonly dubs: ReadonlyArray<Scalars['String']['output']>;
    /** The episode of this video */
    readonly episode: Episode;
    readonly id: Scalars['ID']['output'];
    /** Which regions this video is available in. */
    readonly regions: ReadonlyArray<Scalars['String']['output']>;
    /** The site that is streaming this media. */
    readonly streamer: Streamer;
    /** Languages this is translated to. Usually placed at bottom of media. */
    readonly subs: ReadonlyArray<Scalars['String']['output']>;
    readonly updatedAt: Scalars['ISO8601DateTime']['output'];
    /** The url of the video. */
    readonly url: Scalars['String']['output'];
  };

/** The connection type for Video. */
export type VideoConnection = {
  readonly __typename?: 'VideoConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<VideoEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Video>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type VideoEdge = {
  readonly __typename?: 'VideoEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Video>;
};

/** A manga volume which can contain multiple chapters. */
export type Volume = Unit &
  WithTimestamps & {
    readonly __typename?: 'Volume';
    /** The chapters in this volume. */
    readonly chapters?: Maybe<ChapterConnection>;
    /** The number of chapters in this volume. */
    readonly chaptersCount?: Maybe<Scalars['Int']['output']>;
    readonly createdAt: Scalars['ISO8601DateTime']['output'];
    /** A brief summary or description of the unit */
    readonly description: Scalars['Map']['output'];
    readonly id: Scalars['ID']['output'];
    /** The isbn number of this volume. */
    readonly isbn: ReadonlyArray<Scalars['String']['output']>;
    /** The manga this volume is in. */
    readonly manga: Manga;
    /** The sequence number of this unit */
    readonly number: Scalars['Int']['output'];
    /** The date when this chapter was released. */
    readonly published?: Maybe<Scalars['ISO8601Date']['output']>;
    /** A thumbnail image for the unit */
    readonly thumbnail?: Maybe<Image>;
    /** The titles for this unit in various locales */
    readonly titles: TitlesList;
    readonly updatedAt: Scalars['ISO8601DateTime']['output'];
  };

/** A manga volume which can contain multiple chapters. */
export type VolumeChaptersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A manga volume which can contain multiple chapters. */
export type VolumeDescriptionArgs = {
  locales?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** The connection type for Volume. */
export type VolumeConnection = {
  readonly __typename?: 'VolumeConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<VolumeEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<Volume>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type VolumeEdge = {
  readonly __typename?: 'VolumeEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<Volume>;
};

export enum VolumeSortEnum {
  CreatedAt = 'CREATED_AT',
  Number = 'NUMBER',
  UpdatedAt = 'UPDATED_AT',
}

export type VolumeSortOption = {
  readonly direction: SortDirection;
  readonly on: VolumeSortEnum;
};

export enum WaifuOrHusbandoEnum {
  Husbando = 'HUSBANDO',
  Waifu = 'WAIFU',
}

/** A Wiki Submission is used to either create or edit existing data in our database. This will allow a simple and convient way for users to submit issues/corrections without all the work being left to the mods. */
export type WikiSubmission = WithTimestamps & {
  readonly __typename?: 'WikiSubmission';
  /** The user who created this draft */
  readonly author: Profile;
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  /** The full object that holds all the details for any modifications/additions/deletions made to the entity you are editing. This will be validated using JSON Schema. */
  readonly data?: Maybe<Scalars['JSON']['output']>;
  readonly id: Scalars['ID']['output'];
  /** Any additional information that may need to be provided related to the Wiki Submission */
  readonly notes?: Maybe<Scalars['String']['output']>;
  /** The status of the Wiki Submission */
  readonly status: WikiSubmissionStatusEnum;
  /** The title given to the Wiki Submission. This will default to the title of what is being edited. */
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for WikiSubmission. */
export type WikiSubmissionConnection = {
  readonly __typename?: 'WikiSubmissionConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<WikiSubmissionEdge>>>;
  /** A list of nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<WikiSubmission>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The total amount of nodes. */
  readonly totalCount: Scalars['Int']['output'];
};

export type WikiSubmissionCreateDraftInput = {
  readonly data: Scalars['JSON']['input'];
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of WikiSubmissionCreateDraft */
export type WikiSubmissionCreateDraftPayload = {
  readonly __typename?: 'WikiSubmissionCreateDraftPayload';
  readonly errors?: Maybe<ReadonlyArray<Error>>;
  readonly wikiSubmission?: Maybe<WikiSubmission>;
};

/** An edge in a connection. */
export type WikiSubmissionEdge = {
  readonly __typename?: 'WikiSubmissionEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<WikiSubmission>;
};

export type WikiSubmissionMutations = {
  readonly __typename?: 'WikiSubmissionMutations';
  /** Create a wiki submission draft */
  readonly createDraft?: Maybe<WikiSubmissionCreateDraftPayload>;
  /** Submit a wiki submission draft */
  readonly submitDraft?: Maybe<WikiSubmissionSubmitDraftPayload>;
  /** Update a wiki submission draft */
  readonly updateDraft?: Maybe<WikiSubmissionUpdateDraftPayload>;
};

export type WikiSubmissionMutationsCreateDraftArgs = {
  input: WikiSubmissionCreateDraftInput;
};

export type WikiSubmissionMutationsSubmitDraftArgs = {
  input: WikiSubmissionSubmitDraftInput;
};

export type WikiSubmissionMutationsUpdateDraftArgs = {
  input: WikiSubmissionUpdateDraftInput;
};

export enum WikiSubmissionSortEnum {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type WikiSubmissionSortOption = {
  readonly direction: SortDirection;
  readonly on: WikiSubmissionSortEnum;
};

export enum WikiSubmissionStatusEnum {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export type WikiSubmissionSubmitDraftInput = {
  readonly data: Scalars['JSON']['input'];
  readonly id: Scalars['ID']['input'];
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of WikiSubmissionSubmitDraft */
export type WikiSubmissionSubmitDraftPayload = {
  readonly __typename?: 'WikiSubmissionSubmitDraftPayload';
  readonly errors?: Maybe<ReadonlyArray<Error>>;
  readonly wikiSubmission?: Maybe<WikiSubmission>;
};

export type WikiSubmissionUpdateDraftInput = {
  readonly data: Scalars['JSON']['input'];
  readonly id: Scalars['ID']['input'];
  readonly notes?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of WikiSubmissionUpdateDraft */
export type WikiSubmissionUpdateDraftPayload = {
  readonly __typename?: 'WikiSubmissionUpdateDraftPayload';
  readonly errors?: Maybe<ReadonlyArray<Error>>;
  readonly wikiSubmission?: Maybe<WikiSubmission>;
};

export type WithTimestamps = {
  readonly createdAt: Scalars['ISO8601DateTime']['output'];
  readonly updatedAt: Scalars['ISO8601DateTime']['output'];
};
